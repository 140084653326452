import {Injectable} from '@angular/core';
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class GeneralHelperService {
  returnFormattedDate(dateString: string | number): string {
    const date = new Date(dateString);
    return date.toLocaleString().split(',')[0];
  }

  checkIsToday(dateString: string): boolean {
    const todayString = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    return dateString === todayString;
  }

  returnFormattedDateForFmp(date: Date | string): string {
    const newDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "2-digit", day: "2-digit"};
    const [day, month, year] = newDate.toLocaleDateString('en', options).split(',')[0].split('/');
    return `${year}-${day}-${month}`;
  }

  capitalizeLabels(labels: string[]): string[] {
    return labels.map(label => label.charAt(0).toUpperCase() + label.slice(1));
  }

  transformArrayToObject(array: any[]): any {
    return array.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});
  }

  calculateSumOfArrayValues(array: number[]): number {
    return array.reduce((acc, num) => acc + num, 0);
  }

  isEmpty(obj: any): boolean {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }
}
