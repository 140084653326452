<div class="wrapper-modal">
  <div class="wrapper-content-modal wrapper-content-modal--confirm">
    <div class="content-modal">
      <div class="description-confirm-modal success">
        <i class="fa-regular fa-circle-check"></i>
        <span>You successfully subscribed!</span>
      </div>
      <div class="buttons-confirm-modal">
        <button class="zc-btn zc-btn--primary zc-position-relative" (click)="confirmModal.emit()">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </div>
</div>
