import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toFixed'
})

@Injectable({
  providedIn: 'root'
})

export class ToFixedPipe implements PipeTransform {

  transform(value: any, decimals: number = 2): any {
    return (typeof value === 'number' && value !== null && value !== undefined)
      ? value.toFixed(decimals)
      : value;
  }
}
