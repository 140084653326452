<div>
  <div class="ff-dropdown open xs-mb-0">
    <button class="btn-dropdown"
      #dropdownBtn
      type="button"
      (click)="toggleDropdown($event)"
      [disabled]="disableSelect">
      {{selectedItem ? selectedItem[propForView] : placeholderBtn}}
      <img [ngClass]="{'rotate-icon': dropdownOpened}" src="../../../assets/icons/icon-arrow-left-paggination.svg" alt="">
    </button>
    <div class="dropdown-items"
      #dropdownMenu
      *ngIf="dropdownOpened && filteredItems.length">
      <button class="item"
        type="button"
        *ngFor="let item of filteredItems"
        [disabled]="disableSelect"
        (click)="selectItem($event, item)">
        <span>
          {{propForView ? item[propForView] : item}}
        </span>
      </button>
    </div>
  </div>
</div>

