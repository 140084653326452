import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexTitleSubtitle, ApexStates, ApexPlotOptions, ApexTooltip
} from 'ng-apexcharts';

export type ChartOptions = {
  title: ApexTitleSubtitle;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  states: ApexStates;
  tooltip: ApexTooltip;
  labels: any;
  colors: any[];
};
@Component({
  selector: 'wx-pie-chart',
  templateUrl: './pie-chart.component.html',
  styles: ``
})
export class PieChartComponent implements OnChanges{
  chartOptions: ChartOptions | undefined;
  @Input() data: any;
  @Input() chartTitle: any;
  @Input() colors: string[] = ['#00C08B', '#FFBF05', '#7B4EC7', '#40E0F8', '#fc033d', '#f5e342', '#3d34eb', '#db27ab', '#4287f5', '#c9c3c3', '#db7e27'];
  color = '#c9c3c3';
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.initChart();
    }
  }

  initChart(): void {
    this.chartOptions = {
      series: this.data?.series,
      colors: this.colors,
      chart: {
        width: '100%',
        type: 'pie',
        zoom: {
          enabled: false
        },
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%';
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          offsetY: 60,
        }
      },
      labels: this.data?.labels,
      legend: {
        position: 'bottom',
        floating: true,
        itemMargin: {
          vertical: 2,
        },
      },
      title: {
        text: this.chartTitle,
      },
      states: {
        hover: {
          filter: {
            type: 'lighten',
            value: 0.05,
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }
    };
  }

  protected readonly toolbar = toolbar;
}
