import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InputDebounceTime} from '../../enums/general-enums';

@Component({
  selector: 'wx-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit{
  @Input() searchQuery = '';
  @Input() inputClass = '';
  @Input() searchPlaceholder = '';
  @Input() inputClasses = '';
  searchSubject = new Subject<string>();
  @Output() searchQueryChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    this.debounceSearch();
  }

  debounceSearch(): void {
    this.searchSubject.pipe(
      debounceTime(InputDebounceTime.time),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchQuery = value;
        this.searchQueryChange.emit(value);
      });
  }
}
