import {trigger, style, transition, animate} from '@angular/animations';

export const fadeInOutAnimation = [
    trigger('fadeInOutAnimation', [
        transition('void => *', [
            style({opacity: 0}),
            animate(400, style({opacity: 1}))
        ]),
        transition('* => void', [
            style({opacity: 1}),
            animate(400, style({opacity: 0}))
        ])
    ])
];
