import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvestmentAssetsTabs} from '../../../../../enums/portfolio-enums';
import {PortfolioHttpService} from '../../../../../services/http-services/portfolio/portfolio-http.service';

@Component({
  selector: 'gr-portfolio-assets',
  templateUrl: './portfolio-assets.component.html',
})
export class PortfolioAssetsComponent implements OnInit {
  @Input() assetsData: any;
  @Input() activeAssetPortfolioData: any;
  @Output() clickOnBackArrow: EventEmitter<void> = new EventEmitter<void>();
  tabs: string[] = [InvestmentAssetsTabs.overview, InvestmentAssetsTabs.balanceSheet, InvestmentAssetsTabs.incomeStatement, InvestmentAssetsTabs.cashFlow];
  activeTab: string = InvestmentAssetsTabs.overview;
  protected readonly InvestmentAssetsTabs = InvestmentAssetsTabs;

  constructor(private portfolioHttpService: PortfolioHttpService) {
  }
  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onClickPeopleAlsoWatchCompany(ticker: string): void {
    this.assetsData = null;
    this.activeAssetPortfolioData = null;
    this.portfolioHttpService.getFmpData({ticker: [ticker]}).then((res: any) => {
      this.assetsData = res.data[ticker.toLowerCase()];
    });
  }
}
