import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class BaseUrl implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        const usePaymentIntentApi = req.headers.get('use_payment_intent_api');
        const url = usePaymentIntentApi ? environment.PAYMENT_INTENT_API_ENDPOINT : environment.API_ENDPOINT;
        let apiRequest = req.clone({url: url + req.url});
        if (usePaymentIntentApi) {
          apiRequest = apiRequest.clone({
            headers: apiRequest.headers.delete('use_payment_intent_api')
          });
        }
        return next.handle(apiRequest);
    }
}
