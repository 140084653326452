import {Component, OnInit} from '@angular/core';
import {fadeInOutAnimation} from '../../animations/FadeInOutAnimation';
import {UsersHttpService} from '../../services/http-services/users/users-http.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'wx-users-list',
  templateUrl: './users-list.component.html',
  animations: [fadeInOutAnimation]
})
export class UsersListComponent implements OnInit {
  isInviteNewMemberModalOpen = false;
  usersList: any[] = [];
  userForDelete: any = null;

  constructor(private usersHttpService: UsersHttpService,
              private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.usersHttpService.getMembers().then(res => {
      this.usersList = [...res.pending_invites, ...res.members];
    });
  }

  onConfirmInviteMember(member: any): void {
    this.usersList.push(member);
    this.isInviteNewMemberModalOpen = false
  }

  onConfirmDeleteUser(): void {
    (this.userForDelete.token)
      ? this.usersHttpService.deleteInvitedMember({token: this.userForDelete.token}).then(res => {
          this.usersList = this.usersList.filter(user => user.email !== this.userForDelete.email);
          this.toastrService.success('Successfully deleted user invitation."');
          this.userForDelete = null;
        })
      : this.usersHttpService.deleteMember({id: this.userForDelete.id}).then(res => {
          this.usersList = this.usersList.filter(user => user.id !== this.userForDelete.id);
          this.toastrService.success('Successfully deleted user."');
          this.userForDelete = null;
        });
  }
}
