<div class="wrapper-custom-header" [ngClass]="{'scroll-design': isScrolledFromTop}">
  <div class="left-header">
    <div class="header-item logo">
      <span>PDFolio</span>
    </div>
    <div class="header-item" [routerLink]="[PAGES_AND_PATHS.portfolio.pagePath]" [routerLinkActive]="'active'">
      <span class="desktop">Dashboard</span>
      <span class="mobile"><i class="fa-solid fa-house"></i></span>
    </div>
    <div class="header-item" [routerLink]="[PAGES_AND_PATHS.userList.pagePath]" [routerLinkActive]="'active'">
      <span class="desktop">Users</span>
      <span class="mobile"><i class="fa-solid fa-user"></i></span>
    </div>
    <div class="header-item" [routerLink]="[PAGES_AND_PATHS.settings.pagePath]" [routerLinkActive]="'active'">
      <span class="desktop">Settings</span>
      <span class="mobile"><i class="fa-solid fa-gear"></i></span>
    </div>
  </div>
  <div (click)="authHelperService.clientLogout(PAGES_AND_PATHS.login.pagePath)" class="wrapper-logout">
    <img src="../assets/icons/icon-logout.svg" alt="">
    <span class="desktop">Logout</span>
  </div>
</div>
