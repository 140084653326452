import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PortfolioHttpService} from '../../../../services/http-services/portfolio/portfolio-http.service';
import {EmailRegex} from '../../../../constants/general-variables';
import {UsersHttpService} from "../../../../services/http-services/users/users-http.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'wx-invite-member-modal',
  templateUrl: './invite-member-modal.component.html',
})
export class InviteMemberModalComponent {
  inviteMemberForm!: FormGroup;
  formTriedToSubmit = false;
  isLoaderActive = false;
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private usersHttpService: UsersHttpService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.inviteMemberForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
    });
  }

  submitInviteMemberForm(): void {
    this.formTriedToSubmit = true;
    if (this.inviteMemberForm.valid) {
      this.isLoaderActive = true;
      this.usersHttpService.postInviteMember(this.inviteMemberForm.value).then(res => {
        if (res.success) {
          this.toastrService.success('Invite sent successfully.');
          this.isLoaderActive = false;
          this.confirmModal.emit(res.data);
        }
      }).catch(({error}: {error: { errors: { [key: string]: string } }}) => {
        this.isLoaderActive = false;
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      });;
    }
  }
}
