import {Component, OnDestroy, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {WxClientPortfolio} from '../../interfaces/portfolio-interfaces';
import {PortfolioHttpService} from '../../services/http-services/portfolio/portfolio-http.service';
import {fadeInOutAnimation} from '../../animations/FadeInOutAnimation';
import {fadeInAnimation} from '../../animations/FadeInAnimation';
import {DocumentStatus, PortfolioStatus} from '../../enums/portfolio-enums';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {Router} from '@angular/router';
import {DocumentsHttpService} from '../../services/http-services/documents/documents-http.service';

@Component({
  selector: 'wx-portfolio',
  templateUrl: './portfolio.component.html',
  animations: [fadeInOutAnimation, fadeInAnimation]
})

export class PortfolioComponent implements OnInit, OnDestroy {
  filteredPortfolios: WxClientPortfolio[] = [];
  initialPortfolios: WxClientPortfolio[] = [];
  filteredDocuments: any[] = [];
  initialDocuments: any[] = [];
  searchQuery = '';
  searchQueryDocs = '';
  createNewPortfolioModalOpen = false;
  createNewDocumentModalOpen = false;
  intervalId: any;
  intervalDocId: any;
  isDocumentsShowAsCards = true;
  portfolioForDelete: any = null;
  documentForDelete: any = null;
  isRequestFinished = false;
  isPaymentModalOpen = false;
  isSuccessfullySubscribedModalOpen = false;
  protected readonly PortfolioStatus = PortfolioStatus;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private portfolioHttpService: PortfolioHttpService,
              private documentsHttpService: DocumentsHttpService,
              private toastrService: ToastrService,
              private router: Router,
              public authHelperService: AuthenticationHelperService) {
  }

  ngOnInit(): void {
    this.isPaymentModalOpen = !this.authHelperService.getUserData.first_time_payment;
    if (this.authHelperService.getUserData.first_time_payment) {
      this.getPortfolios();
      this.intervalId = setInterval( () => {
        this.getPortfolios();
      }, 2000);
      this.getDocuments();
      this.intervalDocId = setInterval( () => {
        this.getDocuments();
      }, 2000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    clearInterval(this.intervalDocId);
  }
  getPortfolios(): void {
    this.portfolioHttpService.getPortfolios().then(async res => {
      const portfolioPromises = res.data.map(async (portfolio: WxClientPortfolio) => {
        if (portfolio.status === PortfolioStatus.processing) {
          const progressRes = await this.portfolioHttpService.getPortfolioProgressBar({ id: portfolio.id });
          portfolio.progressBar = progressRes.completed_percentage ? progressRes.completed_percentage : undefined;
        }
        return portfolio;
      });

      const processedPortfolios = await Promise.all(portfolioPromises);
      this.initialPortfolios = processedPortfolios;
      this.filteredPortfolios = processedPortfolios;
      if (this.initialPortfolios.length) {
        if (this.initialPortfolios.every(value => {
          return value.status !== PortfolioStatus.processing;
        })) {
          clearTimeout(this.intervalId);
        }
      } else {
        clearTimeout(this.intervalId);
      }
    });
  }

  getDocuments(): void {
    this.isRequestFinished = false;
    this.documentsHttpService.getDocuments().then(res => {
      this.initialDocuments = res.documents;
      this.filteredDocuments = res.documents;
      if (this.initialDocuments.length) {
        if (this.initialDocuments.every(value => {
          return value.status !== DocumentStatus.processing;
        })) {
          clearTimeout(this.intervalDocId);
        }
      } else {
        clearTimeout(this.intervalDocId);
      }
      this.isRequestFinished = true;
    });
  }

  onConfirmDeletePortfolio(): void {
    this.portfolioHttpService.deletePortfolio({id: this.portfolioForDelete.id}).then(res => {
      this.initialPortfolios = this.initialPortfolios.filter(portfolio => portfolio.id !== this.portfolioForDelete.id);
      this.filteredPortfolios = this.initialPortfolios;
      this.toastrService.success('Successfully deleted portfolio.');
      this.portfolioForDelete = null;
    });
  }

  onConfirmDeleteDocument(): void {
    this.documentsHttpService.deleteDocument({id: this.documentForDelete.id}).then(res => {
      this.initialDocuments = this.initialDocuments.filter(document => document.id !== this.documentForDelete.id);
      this.filteredDocuments = this.initialDocuments;
      this.toastrService.success('Successfully deleted document.');
      this.documentForDelete = null;
    });
  }

  onPortfolioSearchQueryChange(query: string): void {
    this.searchQuery = query;
    if (query) {
      this.filteredPortfolios = this.initialPortfolios.filter((portfolio: WxClientPortfolio) => {
        return portfolio.name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filteredPortfolios = this.initialPortfolios;
    }
  }

  onDocumentsSearchQueryChange(query: string): void {
    this.searchQueryDocs = query;
    if (query) {
      this.filteredDocuments = this.initialDocuments.filter((document: any) => {
        return document.name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filteredDocuments = this.initialDocuments;
    }
  }

  onConfirmCreatePortfolioForm(formData: WxClientPortfolio): void {
    this.initialPortfolios.push(formData);
    this.onPortfolioSearchQueryChange(this.searchQuery);
    this.createNewPortfolioModalOpen = false;
  }

  onConfirmCreateDocumentForm(formData: any): void {
    this.initialDocuments.push(formData);
    this.onDocumentsSearchQueryChange(this.searchQueryDocs);
    this.createNewDocumentModalOpen = false;
    this.intervalDocId = setInterval( () => {
      this.getDocuments();
    }, 2000);
  }

  navigateToPortfolio(portfolio: any): void {
    (portfolio.status === PortfolioStatus.processing)
      ? this.toastrService.warning('Portfolio processing pdf, try again later.')
      : this.router.navigate(
        [PAGES_AND_PATHS.singlePortfolio.pagePath, portfolio.id],
        {state: {data: portfolio}}
      );
  }

  navigateToDocuments(document: any): void {
    (document.status === DocumentStatus.processing)
      ? this.toastrService.warning('Document processing pdf, try again later.')
      : this.router.navigate([PAGES_AND_PATHS.singleDocument.pagePath, document.id]);
  }

  onConfirmPaymentModal(): void {
    this.isPaymentModalOpen = false;
    this.authHelperService.setUserData({...this.authHelperService.getUserData, first_time_payment: true})
  }

  onConfirmSubscriptionSuccessfully(): void {
    this.isSuccessfullySubscribedModalOpen = false;
    history.replaceState({ ...history.state, subscribed: false }, '', window.location.href);
  }
}
