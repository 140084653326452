import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {PortfolioHttpService} from '../../../services/http-services/portfolio/portfolio-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChartTitles} from '../../../enums/chart-enums';
import {WxClientPortfolio, WxPortfolioData} from '../../../interfaces/portfolio-interfaces';
import {PortfolioStatus} from '../../../enums/portfolio-enums';
import {fadeInOutAnimation} from '../../../animations/FadeInOutAnimation';
import {GeneralHelperService} from '../../../services/helpers/general-helper';
import {ChartHelperService} from '../../../services/helpers/chart-helper.service';
import {PortfolioHelperService} from '../../../services/helpers/portfolio-helper';
import {formatDate} from '@angular/common';
import {BaseCurrencyItems} from '../../../constants/general-variables';
import {UiHelperService} from '../../../services/helpers/ui-helper.service';

@Component({
  selector: 'app-single-portfolio',
  templateUrl: './single-portfolio.component.html',
  animations: [fadeInOutAnimation]
})
export class SinglePortfolioComponent implements OnInit {
  isPortfolioAssetsShow = false;
  isAssetsPriceRequestFinished = false;
  singlePortfolioData!: WxClientPortfolio;
  portfolioId!: number;
  portfolioData!: WxPortfolioData;
  fmpCompanyData: any;
  formattedMonthlyPerformanceData: any;
  isUploadModalShow = false;
  allCompaniesFmpPrices: any;
  formattedCurrencyExposureData: any;
  formattedAssetAllocationData: any;
  formattedAllocationBySectorData: any;
  formattedCountryExposureData: any;
  initialDate = this.generalHelperService.returnFormattedDate(Date.now());
  selectedDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  isInitialDate = true;
  activeTicker = '';
  activeCompanyPortfolioData: any;
  totalPortfoliosValuation = 0;
  initialFilteredAssets: any[] = [];
  filteredAssets: any[] = [];
  filterItems: string[] = [];
  currencyValues: any;
  isBaseCurrencyDropdownOpen = false;
  selectedBaseCurrency = 'USD';
  @ViewChild('dropdownCurrencyBtn') dropdownCurrencyBtn: ElementRef | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly ChartTitles = ChartTitles;
  protected readonly PortfolioStatus = PortfolioStatus;
  protected readonly BaseCurrencyItems = BaseCurrencyItems;

  constructor(private portfolioHttpService: PortfolioHttpService,
              private generalHelperService: GeneralHelperService,
              private chartHelperService: ChartHelperService,
              private activatedRoute: ActivatedRoute,
              private portfolioHelperService: PortfolioHelperService,
              private renderer: Renderer2,
              private router: Router,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.portfolioId = res.id;
      this.singlePortfolioData = {...history.state.data};
      if (this.singlePortfolioData.status === PortfolioStatus.completed) {
        this.getLatestCurrencyExchangeRates();

      }
    });
    this.listenToClickDropdown();
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownCurrencyBtn, this.dropdownCurrencyBtn, e)) {
        this.isBaseCurrencyDropdownOpen = false;
      }
    });
  }

  getSinglePortfolioData(): void {
    this.portfolioHttpService.getPortfolioStatementData({id: this.portfolioId}).then(res => {
      this.formattedPortfolioData(res.data);
      if (Object.keys(this.portfolioData.fmp).length) {
        this.formatterAllocationBySectorData(Object.values(this.portfolioData.fmp));
        this.formatterCountryExposureData(Object.values(this.portfolioData.fmp));
        this.getAllCompanyPdfHistoricalPrice(this.generalHelperService.returnFormattedDateForFmp(new Date()));
      } else {
        this.formatterCurrencyExposureData(this.portfolioData.assets);
        this.returnTotalPortfolioValuationForCustodian(this.portfolioData);
        this.isAssetsPriceRequestFinished = true;
      }
      this.formatterMonthlyPerformanceData(Object.values(this.portfolioData.monthlyPerformance));
      if (this.portfolioData?.assets?.assets) {
        this.initialFilteredAssets = this.portfolioData.assets.assets.filter((element: any) => element.assetClassType.toLowerCase() !== 'portfolio');
        this.filteredAssets = this.initialFilteredAssets;
        this.formatterAssetAllocationData(this.initialFilteredAssets);
      } else if (this.portfolioData.assets) {
        this.initialFilteredAssets = this.portfolioData.assets.filter((element: any) => element.assetClassType.toLowerCase() !== 'portfolio');
        this.filteredAssets = this.initialFilteredAssets;
        this.formatterAssetAllocationData(this.initialFilteredAssets);
      }
    });
  }

  formattedPortfolioData(portfolio: any): void {
    portfolio.assets = [];
    portfolio.fmp = {};
    portfolio.price_history = null;
    portfolio.monthlyPerformance = {};
    portfolio.ytd = 0;
    Object.keys(portfolio.files).forEach((key: string) => {
      if (portfolio.files[key].fmp) {
        portfolio.fmp = {...portfolio.fmp, ...this.generalHelperService.transformArrayToObject(portfolio.files[key].fmp)};
      }
      if (portfolio.files[key].price_history) {
        portfolio.price_history = {...portfolio.price_history, ...portfolio.files[key].price_history};
      }
      portfolio.ytd += portfolio.files[key].ytd?.ytdReturnPercentage ? portfolio.files[key].ytd.ytdReturnPercentage : 0;
      if (this.generalHelperService.isEmpty(portfolio.monthlyPerformance)) {
        portfolio.monthlyPerformance[key] = {
          monthlyPerformance: portfolio.files[key].monthlyPerformance,
          pdfName: key,
          statementDate: portfolio.files[key].statementDate.statementDate,
          custodian: portfolio.files[key].statementDate.custodianInformation
        };
      } else {
        const foundPdfWithSameCustodianName: any = Object.values(portfolio.monthlyPerformance).find((element: any) => portfolio.files[key].statementDate.custodianInformation.name === element.custodian.name);
        if (foundPdfWithSameCustodianName) {
          if (new Date(portfolio.files[key].statementDate.statementDate) > new Date(foundPdfWithSameCustodianName.statementDate)) {
            delete portfolio.monthlyPerformance[foundPdfWithSameCustodianName.pdfName];
            portfolio.monthlyPerformance[key] = {
              monthlyPerformance: portfolio.files[key].monthlyPerformance,
              pdfName: key,
              statementDate: portfolio.files[key].statementDate.statementDate,
              custodian: portfolio.files[key].statementDate.custodianInformation
            };
          }
        } else {
          portfolio.monthlyPerformance[key] = {
            monthlyPerformance: portfolio.files[key].monthlyPerformance,
            pdfName: key,
            statementDate: portfolio.files[key].statementDate.statementDate,
            custodian: portfolio.files[key].statementDate.custodianInformation
          };
        }
      }
      portfolio.files[key].assets.forEach((asset: any) => {
        asset.pdfName = key;
        if (asset.assetClassType.toLowerCase() === 'equities' || asset.assetClassType.toLowerCase() === 'etf' || asset.assetClassType.toLowerCase() === 'equity') {
          asset.fmpPriceOnPortfolioDate = asset.ticker ? ((portfolio.price_history && portfolio.price_history[asset.ticker.toLowerCase()] && portfolio.price_history[asset.ticker.toLowerCase()].price_from_pdf?.length) ? portfolio.price_history[asset.ticker.toLowerCase()].price_from_pdf[0].close : (portfolio.fmp[asset.ticker].length ? portfolio.fmp[asset.ticker][0].price : 0)) : 0;
        }
        if (asset.assetClassType.toLowerCase() === 'cash') {
          asset.name = asset.positionCurrency;
        }
        const foundAsset = portfolio.assets.find((element: any) => {
          return (asset.ticker ? element.ticker === asset.ticker : element.name === asset.name);
        });
        if (foundAsset) {
          foundAsset.pdf.push(asset);
          if (Object.keys(portfolio.monthlyPerformance).includes(asset.pdfName)) {
            if (Object.keys(portfolio.monthlyPerformance).length > 1) {
              foundAsset.quantity = foundAsset.quantity + Number(asset.quantity.toString().replace(/[\s-$%',a-zA-Z]+/g, ''));
              foundAsset.valuationInOriginalCurrency = foundAsset.valuationInOriginalCurrency + Number(asset.valuationInOriginalCurrency.toString().replace(/[\s-$%',a-zA-Z]+/g, ''));
              if (foundAsset.assetClassType.toLowerCase() === 'equities' || foundAsset.assetClassType.toLowerCase() === 'etf' || foundAsset.assetClassType.toLowerCase() === 'equity') {
                foundAsset.hasMoreDiferentCustodians = true;
              }
            } else {
              foundAsset.quantity = asset.quantity;
              foundAsset.valuationInOriginalCurrency = asset.valuationInOriginalCurrency;
              foundAsset.pdfName = asset.pdfName;
              foundAsset.fmpPriceOnPortfolioDate = asset.fmpPriceOnPortfolioDate;
              foundAsset.positionCurrency = asset.positionCurrency;
              foundAsset.weight = asset.weight;
            }
          }
        } else {
          portfolio.assets.push({...asset, pdf: [{...asset}]});
        }
      });
    });
    portfolio.ytd = portfolio.ytd / Object.keys(portfolio.files).length;
    this.portfolioData = portfolio;
  }

  getAllCompanyPdfHistoricalPrice(date: string): void {
    this.isAssetsPriceRequestFinished = false;
    const ticker = Object.keys(this.portfolioData.fmp);
    if (ticker.length) {
      this.portfolioHttpService.getAllPdfHistoricalPrice({
        ticker,
        from: date,
        to: date,
        timeframe: '1day'
      }).then(res => {
        this.allCompaniesFmpPrices = res.data;
        this.portfolioData.assets.forEach((asset: any) => {
          if ((asset.assetClassType.toLowerCase() === 'equities' || asset.assetClassType.toLowerCase() === 'etf' || asset.assetClassType.toLowerCase() === 'equity')) {
            asset.pdf.forEach((pdfAsset: any) => {
              pdfAsset.PL = this.portfolioHelperService.returnPL(pdfAsset.fmpPriceOnPortfolioDate, {
                quantity: pdfAsset.quantity,
                currentFmpPrice: (pdfAsset.ticker ? this.portfolioHelperService.returnFmpPrice(pdfAsset.ticker, (this.isInitialDate ? this.portfolioData.fmp : this.allCompaniesFmpPrices), this.isInitialDate) : 0)
              });
            });
            if (Object.keys(this.portfolioData.monthlyPerformance).includes(asset.pdfName)) {
              if (Object.keys(this.portfolioData.monthlyPerformance).length > 1) {
                asset.PL = 0;
                asset.pdf.forEach((pdfAsset: any) => {
                  asset.PL += pdfAsset.PL;
                });
              } else {
                asset.PL = this.portfolioHelperService.returnPL(asset.fmpPriceOnPortfolioDate, {
                  quantity: asset.quantity,
                  currentFmpPrice: (asset.ticker ? this.portfolioHelperService.returnFmpPrice(asset.ticker, (this.isInitialDate ? this.portfolioData.fmp : this.allCompaniesFmpPrices), this.isInitialDate) : 0)
                });
              }
            }
          }
        });
        this.formatterCurrencyExposureData(this.portfolioData.assets);
        this.returnTotalPortfolioValuationForCustodian(this.portfolioData);
        this.isAssetsPriceRequestFinished = true;
      });
    } else {
      this.isAssetsPriceRequestFinished = true;
    }
  }

  formatterMonthlyPerformanceData(data: any): void {
    const chartData: any = {
      series: [],
      title: ChartTitles.monthlyPerformance,
      colors: ['#9E94E6', '#11BB8D'],
      tickAmount: 6
    };
    data.forEach((element: any) => {
      chartData.series.push({
        name: element.custodian.name + ' ' + formatDate(new Date(element.statementDate), 'dd.MM.yyyy.', 'en'),
        data: Object.keys(element.monthlyPerformance).map((dateKey) => {
          return {
            x: new Date(dateKey).getTime(),
            y: element.monthlyPerformance[dateKey]
          };
        })
      })
    });
    this.formattedMonthlyPerformanceData = chartData;
  }

  formatterCurrencyExposureData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const allPositionCurrencies = data.map((element: any) => {
      return element.positionCurrency;
    });
    chartData.labels = Array.from(new Set(allPositionCurrencies));
    chartData.labels.forEach((label: any) => {
      chartData.series.push(data.reduce((accumulator: number, currentValue: any) => {
        if (currentValue.positionCurrency === label) {
          if ((currentValue.assetClassType === 'equity' || currentValue.assetClassType === 'etf' || currentValue.assetClassType === 'equities') && currentValue.ticker && currentValue.quantity) {
            return accumulator + (typeof currentValue.quantity === 'string' ? Number(currentValue.quantity.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.quantity) *
              (this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf']?.length ? this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf'][0].close : 0);
          } else {
            return accumulator + (currentValue.valuationInOriginalCurrency ? (typeof currentValue.valuationInOriginalCurrency === 'string' ? Number(currentValue.valuationInOriginalCurrency.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.valuationInOriginalCurrency) : 0);
          }
        }
        return accumulator;
      }, 0));
    });
    this.returnTotalPortfolioValuation(chartData);
  }

  formatterAllocationBySectorData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const sectors = data
      .map((e: any) => {
        if (e.length) {
          return e[0].sector.toLowerCase();
        }
      })
      .filter((elem: any) => elem !== undefined && elem !== '');
    chartData.labels = Array.from(new Set(sectors));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    sectors.forEach((element: string) => {
      seriesData[element.toLowerCase()] += 1;
    });
    chartData.labels = this.generalHelperService.capitalizeLabels(chartData.labels);
    chartData.series = Object.values(seriesData);
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedAllocationBySectorData = chartData;
  }

  formatterCountryExposureData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const countries = data
      .map((e: any) => {
        if (e.length) {
          return e[0].country;
        }
      }).filter((elem: any) => elem !== undefined && elem !== '' && elem !== null);
    chartData.labels = Array.from(new Set(countries));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    countries.forEach((element: string) => {
      seriesData[element.toLowerCase()] += 1
    });
    chartData.series = Object.values(seriesData);
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedCountryExposureData = chartData;
  }


  formatterAssetAllocationData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const assetsTypes = data.map((value: any) => {
      return value.assetClassType.toString().charAt(0).toUpperCase() + value.assetClassType.slice(1);
    });
    chartData.labels = Array.from(new Set(assetsTypes));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    assetsTypes.forEach((element: string) => {
      (seriesData.hasOwnProperty(element.toLowerCase()))
        ? seriesData[element.toLowerCase()] = seriesData[element.toLowerCase()] + 1
        : seriesData.others = seriesData.others + 1;
    });
    chartData.series = Object.values(seriesData);
    this.filterItems = [...chartData.labels];
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedAssetAllocationData = chartData;
  }

  getFmpData(): void {
    this.portfolioHttpService.getFmpData({ticker: [this.activeTicker]}).then(res => {
      this.fmpCompanyData = res.data;
    });
  }

  onDateChange(date: any): void {
    this.selectedDate = date;
    this.onCurrencyChange(date, this.selectedBaseCurrency);
  }

  onCurrencyChange(date: any, baseCurrency: string): void {
    this.selectedBaseCurrency = baseCurrency;
    const formattedDateFmp = this.generalHelperService.returnFormattedDateForFmp(date);
    this.isInitialDate = date.toString() === new Date(new Date().setHours(0, 0, 0, 0)).toString();
    this.generalHelperService.checkIsToday(formattedDateFmp) ? this.getLatestCurrencyExchangeRates() : this.getHistoryCurrencyExchangeRates({date: formattedDateFmp, from: this.selectedBaseCurrency});
    this.getAllCompanyPdfHistoricalPrice(formattedDateFmp);
  }

  closeUploadStatementModalAndRedirect(): void {
    this.isUploadModalShow = false;
    this.router.navigate([PAGES_AND_PATHS.portfolio.pagePath]);
  }

  handleOpenSingleAssets(assetType: string, ticker: string, portfolio: any): void {
    if ((assetType.toLowerCase() === 'equity' || assetType.toLowerCase() === 'equities' || assetType.toLowerCase() === 'eft') && ticker) {
      this.activeTicker = ticker;
      this.activeCompanyPortfolioData = portfolio;
      this.getFmpData();
      this.isPortfolioAssetsShow = true;
    }
  }

  getLatestCurrencyExchangeRates(): void {
    this.portfolioHttpService.getLatestExchangeRates({from: this.selectedBaseCurrency}).then(res => {
      this.currencyValues = res.data.latest_currency.data;
      this.getSinglePortfolioData();
    });
  }

  getHistoryCurrencyExchangeRates(urlParams: any): void {
    this.portfolioHttpService.getHistoryExchangeRates({...urlParams}).then(res => {
      this.currencyValues = res.data.latest_currency.data;
    });
  }

  returnTotalPortfolioValuation(data: any): void {
    let totalPortfolio = 0;
    const seriesInActiveCurrency = data.labels.map((label: string, index: number) => {
      return (data.series[index] / (label === 'XAU' ? 1 : this.currencyValues[data.labels[index]]));
    });
    totalPortfolio = this.generalHelperService.calculateSumOfArrayValues(seriesInActiveCurrency);
    data.series = seriesInActiveCurrency.map((element: number) => {
      return (element / totalPortfolio) * 100;
    });
    this.formattedCurrencyExposureData = data;
  }

  returnTotalPortfolioValuationForCustodian(data: any): void {
    let totalPortfolio = 0;
    Object.keys(data.monthlyPerformance).forEach(key => {
      const chartData: any = {series: [], labels: []};
      const allPositionCurrencies = data.assets.map((element: any) => {
        return element.positionCurrency;
      });
      chartData.labels = Array.from(new Set(allPositionCurrencies));
      chartData.labels.forEach((label: any) => {
        chartData.series.push(data.assets.reduce((accumulator: number, currentValue: any) => {
          if (currentValue.positionCurrency === label && currentValue.pdfName === key) {
            if ((currentValue.assetClassType === 'equity' || currentValue.assetClassType === 'etf' || currentValue.assetClassType === 'equities') && currentValue.ticker && currentValue.quantity) {
              return accumulator + (typeof currentValue.quantity === 'string' ? Number(currentValue.quantity.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.quantity) *
                (this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf']?.length ? this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf'][0].close : 0);
            } else {
              return accumulator + (currentValue.valuationInOriginalCurrency ? (typeof currentValue.valuationInOriginalCurrency === 'string' ? Number(currentValue.valuationInOriginalCurrency.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.valuationInOriginalCurrency) : 0);
            }
          }
          return accumulator;
        }, 0));
      });
      const seriesInActiveCurrency = chartData.labels.map((label: string, index: number) => {
        return (chartData.series[index] / (label === 'XAU' ? 1 : this.currencyValues[chartData.labels[index]]));
      });
      this.portfolioData.monthlyPerformance[key].custodianTotal = this.generalHelperService.calculateSumOfArrayValues(seriesInActiveCurrency);
      totalPortfolio += this.portfolioData.monthlyPerformance[key].custodianTotal;
    });
    this.totalPortfoliosValuation = totalPortfolio;
  }

  onFilterSelected(selectedFilters: any[]): void {
    const lowerCaseSelectedFilters = selectedFilters.map(element => element.toLowerCase());
    (selectedFilters.length)
      ? this.filteredAssets = this.initialFilteredAssets.filter(element => lowerCaseSelectedFilters.includes(element.assetClassType.toLowerCase()))
      : this.filteredAssets = this.initialFilteredAssets;
  }
}
