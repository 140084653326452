import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartHelperService} from '../../../services/helpers/chart-helper.service';
import {ChartOptions} from '../../../types/chart-types';
import {ChartTitles} from '../../../enums/chart-enums';
@Component({
  selector: 'wx-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent implements OnChanges{
  @Input() chartType = '';
  @Input() data: any;
  chartOptions: ChartOptions | undefined;

  constructor(private chartHelperService: ChartHelperService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.initChart(changes.data.currentValue, this.chartType);
    }
  }

  initChart(chartData: any, chartType: string): void {
    switch (chartType) {
      case ChartTitles.historicalPrice:
        this.chartOptions = this.chartHelperService.returnHistoricalPriceBarChartOptions(chartData);
        break;
      case ChartTitles.earnings:
        this.chartOptions = this.chartHelperService.returnEarningBarChartOptions(chartData);
        break;
    }
  }
}
