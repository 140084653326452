import {Component, Input} from '@angular/core';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'wx-document-preview',
  templateUrl: './document-preview.component.html',
})
export class DocumentPreviewComponent {
  @Input() documentUrl: SafeResourceUrl | null = null;
}
