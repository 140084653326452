import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentsHttpService} from '../../services/http-services/documents/documents-http.service';
import {UploadFileStatus} from '../../enums/portfolio-enums';
import {ToastrService} from 'ngx-toastr';
import {fadeInAnimation} from '../../animations/FadeInAnimation';

@Component({
  selector: 'wx-create-document-modal',
  templateUrl: './create-document-modal.component.html',
  animations: [fadeInAnimation]
})
export class CreateDocumentModalComponent {
  createDocumentForm!: FormGroup;
  formTriedToSubmit = false;
  isDragOver = false;
  file: File | null = null;
  uploadFileStatus = UploadFileStatus.uploadDocument;
  isLoaderActive = false;
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>();
  protected readonly UploadFileStatus = UploadFileStatus;
  constructor(private formBuilder: FormBuilder,
              private toasterHelperService: ToastrService,
              private documentsHttpService: DocumentsHttpService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createDocumentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      file: [null, [Validators.required]],
    });
  }

  onFileChange(event: any): void {
    this.file = event.target.files[0];
    this.uploadFile();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.file = files[0];
      this.uploadFile();
    }
  }

  uploadFile(): void {
    if (this.file?.type !== 'application/pdf') {
      this.toasterHelperService.warning('Only .pdf format is supported');
      return;
    } else if (this.file?.size > 10485760) {
      this.toasterHelperService.warning('Document larger than 10MB');
      return;
    } else {
      this.createDocumentForm.patchValue({file: this.file});
      this.uploadFileStatus = UploadFileStatus.uploadedDocument;
    }
  }

  removeFile(): void {
    this.file = null;
    this.uploadFileStatus = UploadFileStatus.uploadDocument;
    this.createDocumentForm.controls.file.reset();
  }

  submitCreateDocumentForm(): void {
    this.formTriedToSubmit = true;
    if (this.createDocumentForm.valid) {
      this.isLoaderActive = true;
      const formData = new FormData();
      formData.append('name', this.createDocumentForm.controls.name.value);
      formData.append('docpdf', this.createDocumentForm.controls.file.value);
      this.documentsHttpService.postCreateDocument(formData).then(res => {
        this.isLoaderActive = false;
        this.toasterHelperService.success(res.message);
        this.confirmModal.emit(res.document);
      });
    }
  }
}
