import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const PAYMENT_ROUTES = {
  PAYMENT_INTENT: 'create-setup-intent',
  GET_PAYMENT_METHOD: 'payment-method',
  SET_PAYMENT_METHOD: 'set-payment-method',
  NEW_SUBSCRIPTION: 'new-subscription',
  GET_ALL_INVOICES: 'get-all-invoices',
  CANCEL_SUBSCRIPTION: 'cancel-subscription',
  RESUME_SUBSCRIPTION: 'resume-subscription',
};

@Injectable({
  providedIn: 'root'
})
export class PaymentHttpService {
  constructor(private httpClient: HttpClient) {}

  paymentIntentReq(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('use_payment_intent_api', 'true');
    return this.httpClient.post(PAYMENT_ROUTES.PAYMENT_INTENT, {}, {headers}) as any;
  }
  paymentIntent(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.paymentIntentReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getPaymentMethodReq(): Observable<any> {
    return this.httpClient.get(PAYMENT_ROUTES.GET_PAYMENT_METHOD) as any;
  }

  getPaymentMethod(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPaymentMethodReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private setPaymentMethodReq(bodyParam: any): Observable<any> {
    return this.httpClient.post(PAYMENT_ROUTES.SET_PAYMENT_METHOD, bodyParam) as any;
  }

  setPaymentMethod(bodyParam: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.setPaymentMethodReq(bodyParam).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private userSubscribeReq(reqBody: any): Observable<any> {
    return this.httpClient.post(PAYMENT_ROUTES.NEW_SUBSCRIPTION, reqBody) as any;
  }

  userSubscribe(reqBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userSubscribeReq(reqBody).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getInvoicesReq(): Observable<any> {
    return this.httpClient.get(PAYMENT_ROUTES.GET_ALL_INVOICES) as any;
  }

  getInvoices(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getInvoicesReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private cancelSubscriptionReq(): Observable<any> {
    return this.httpClient.post(PAYMENT_ROUTES.CANCEL_SUBSCRIPTION, {}) as any;
  }

  cancelSubscription(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.cancelSubscriptionReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private resumeSubscriptionReq(): Observable<any> {
    return this.httpClient.post(PAYMENT_ROUTES.RESUME_SUBSCRIPTION, {}) as any;
  }

  resumeSubscription(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.resumeSubscriptionReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
