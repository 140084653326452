import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WxClientPortfolio} from '../../interfaces/portfolio-interfaces';
import {PortfolioHttpService} from '../../services/http-services/portfolio/portfolio-http.service';

@Component({
  selector: 'wx-create-portfolio-modal',
  templateUrl: './create-portfolio-modal.component.html',
})
export class CreatePortfolioModalComponent implements OnInit {
  createPortfolioForm!: FormGroup;
  formTriedToSubmit = false;
  isLoaderActive = false;
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<WxClientPortfolio> = new EventEmitter<WxClientPortfolio>();
  constructor(private formBuilder: FormBuilder,
              private portfolioHttpService: PortfolioHttpService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createPortfolioForm = this.formBuilder.group({
      client_name: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  submitCreatePortfolioForm(): void {
    this.formTriedToSubmit = true;
    if (this.createPortfolioForm.valid) {
      this.isLoaderActive = true;
      this.portfolioHttpService.postCreatePortfolio(this.createPortfolioForm.value).then(res => {
        this.isLoaderActive = false;
        this.confirmModal.emit(res.data);
      });
    }
  }
}
