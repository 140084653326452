import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'returnFormattedRatioKey',
})
export class ReturnFormattedRatioKey implements PipeTransform {
  transform(value: string): string {
    const extensionInBrackets = `(${value.slice(-3)})`;
    const formattedFirstPartOfValue = value
      .slice(0, -3)
      .split(/(?=[A-Z])/)
      .map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1);
      }).join(' ');
    return `${formattedFirstPartOfValue} ${extensionInBrackets}`;

  }
}
