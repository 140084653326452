import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuardService} from './guards/auth-guard.service';
import {OuterPagesGuardService} from './guards/outer-pages-guard.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {BaseUrl} from './interceptors/base-url.interceptor';
import {SideMenuModule} from './components/side-menu/side-menu.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {ToastrModule} from 'ngx-toastr';
import {HeaderModule} from './components/header/header.module';
import {MarkdownModule} from "ngx-markdown";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    MarkdownModule.forRoot(),
    HttpClientModule,
    SideMenuModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HeaderModule,
  ],
  providers: [
    AuthGuardService,
    OuterPagesGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BaseUrl, multi: true},
    MatDatepickerModule,
    MatNativeDateModule,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
