import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChartHelperService} from '../../../services/helpers/chart-helper.service';
import {ChartOptions} from '../../../types/chart-types';
import {ChartTitles} from '../../../enums/chart-enums';
import {ChartComponent} from "ng-apexcharts";

@Component({
  selector: 'wx-area-chart',
  templateUrl: './area-chart.component.html',
})
export class AreaChartComponent implements OnChanges {
  @Input() data: any;
  @Input() chartType!: string;
  @ViewChild('chartArea') chartArea!: ChartComponent;
  chartOptions: ChartOptions | undefined;

  constructor(private chartHelperService: ChartHelperService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.initChart(changes.data.currentValue);
    }
  }

  initChart(chartData: any): void {
    switch (this.chartType) {
      case ChartTitles.historicalPrice:
        this.chartOptions = this.chartHelperService.returnHistoricalPriceAreaChartOptions(chartData);
        break;
      case ChartTitles.financialStatement:
        this.chartOptions = this.chartHelperService.returnFinancialStatementAreaChartOptions(chartData);
        break;
      case ChartTitles.monthlyPerformance:
        this.chartOptions = this.chartHelperService.returnMonthlyPerformanceAreaChartOptions(chartData, this.highlightSeries.bind(this));
        break;
    }
  }

  highlightSeries(seriesIndex: number) {
    const allSeries = this.chartOptions?.series;
    allSeries.forEach((series: any, index: number) => {
      this.chartArea.hideSeries(allSeries[index].name);
    });
    setTimeout(() => {
      this.chartArea.showSeries(allSeries[seriesIndex].name);
    });
  }
}
