import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {loadStripe, Stripe, StripeAddressElement, StripeCardElement, StripeElements} from "@stripe/stripe-js";
import {environment} from "../../../../../environments/environment";
import {PaymentHttpService} from "../../../../services/http-services/payment/payment-http.service";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Countries} from "../../../../constants/general-variables";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'wx-payment-modal',
  templateUrl: './payment-modal.component.html',
})
export class PaymentModalComponent implements OnInit {
  step = 1;
  clientSecret = '';
  stripe: Stripe | null = null;
  elements!: StripeElements;
  cardElement!: StripeCardElement;
  cardElementTouched = false;
  countryForm!: FormGroup;
  formTriedToSubmit = false
  isLoaderActive = false;
  countries = Countries;
  @ViewChild('cardErrors', {static: false}) cardErrors!: ElementRef;
  @ViewChild('cardElementContainer', {static: false}) cardElementContainer!: ElementRef;
  @Output() confirmPayment: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastrService: ToastrService,
              private paymentHttpService: PaymentHttpService) {
  }

  async ngOnInit(): Promise<void> {
    this.buildForm();
    this.stripe = await loadStripe(environment.STRIPE_KEY);
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.cardElement = this.elements.create('card', {
        classes: {
          base: 'card-payment'
        },
        style: {
          base: {
            color: '#32325d',
            lineHeight: '44px',
            fontFamily: '"Archivo Regular, Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#8d8d8d'
            }
          },
          invalid: {
            color: '#df1b41',
            iconColor: '#df1b41'
          }
        },
        hidePostalCode: true
      });
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (event) => {
        this.cardElementTouched = true;
        if (event.error) {
          this.cardErrors.nativeElement.textContent = event.error.message;
          this.cardElementContainer.nativeElement.style.borderColor = '#df1b41';
          this.cardElementContainer.nativeElement.style.borderWidth = '2px';
          this.cardErrors.nativeElement.style.color = '#df1b41';
          this.cardErrors.nativeElement.style.fontWeight = '300';
          this.cardErrors.nativeElement.style.fontSize = '15px';
          this.cardErrors.nativeElement.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';
        } else if (event.empty) {
          this.cardErrors.nativeElement.textContent = 'Please enter your card details.';
          this.cardElementContainer.nativeElement.style.borderColor = '#df1b41';
          this.cardElementContainer.nativeElement.style.borderWidth = '2px';
          this.cardErrors.nativeElement.style.color = '#df1b41';
          this.cardErrors.nativeElement.style.fontWeight = '300';
          this.cardErrors.nativeElement.style.fontSize = '15px';
          this.cardErrors.nativeElement.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';
        } else if (!event.complete) {
          this.cardErrors.nativeElement.textContent = 'Please enter your card details.';
          this.cardElementContainer.nativeElement.style.borderColor = '#df1b41';
          this.cardElementContainer.nativeElement.style.borderWidth = '2px';
          this.cardErrors.nativeElement.style.color = '#df1b41';
          this.cardErrors.nativeElement.style.fontWeight = '300';
          this.cardErrors.nativeElement.style.fontSize = '15px';
          this.cardErrors.nativeElement.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';
        } else {
          this.cardErrors.nativeElement.textContent = '';
          this.cardElementContainer.nativeElement.style.borderColor = '#e6e6e6';
          this.cardElementContainer.nativeElement.style.borderWidth = '1px';
        }
      });
    }
    this.getPaymentIntent();
  }

  buildForm(): void {
    this.countryForm = this.formBuilder.group({
      country: ['', Validators.required],
    });
  }

  getPaymentIntent(): void {
    this.paymentHttpService.paymentIntent().then((res: any) => {
      this.clientSecret = res.client_secret;
    });
  }

  onCountrySelected(selectedCountry: any): void {
    this.countryForm.controls.country.patchValue(selectedCountry.code)
  }

  async submitRegisterForm(event: Event): Promise<void> {
    event.preventDefault();
    this.formTriedToSubmit = true;
    if (!this.cardElementTouched) {
      this.cardErrors.nativeElement.textContent = 'Please enter your card details';
      this.cardElementContainer.nativeElement.style.borderColor = '#df1b41';
      this.cardElementContainer.nativeElement.style.borderWidth = '2px';
      this.cardErrors.nativeElement.style.color = '#df1b41';
      this.cardErrors.nativeElement.style.fontWeight = '300';
      this.cardErrors.nativeElement.style.fontSize = '15px';
      this.cardErrors.nativeElement.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif';
    }
    if (this.countryForm.valid && this.stripe && !this.cardErrors.nativeElement.textContent) {
      this.isLoaderActive = true;
      const {setupIntent, error} = await this.stripe.confirmCardSetup(
        this.clientSecret, {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              address: {...this.countryForm.value}
            },
          }
        }
      );
      if (error) {
        console.log(error);
      } else {
        const reqObj = {
          paymentMethod: setupIntent.payment_method,
        };
        this.paymentHttpService.setPaymentMethod(reqObj).then((paymentResponse: any) => {
          this.paymentHttpService.userSubscribe({}).then((subscriptionResponse: any) => {
            this.isLoaderActive = false;
            this.step = 3;
          }).catch(error => {
            this.isLoaderActive = false;
            this.toastrService.error(error.error.message);
          });
        }).catch(error => {
          this.isLoaderActive = false;
          this.toastrService.error(error.error.message);
        });
      }
    }
  }
}
