<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <span class="title-page">Users</span>
      </div>
      <div class="header-content-page-right">
        <button (click)="isInviteNewMemberModalOpen = true" class="zc-btn zc-btn--primary">Invite member</button>
      </div>
    </div>

    <div *ngIf="usersList" class="wrapper-table-documents">
      <div class="zc-table min-width-700">
        <div class="zc-header-table">
          <div class="zc-header-row">
            <div class="zc-header-column">
              <span>User</span>
            </div>

            <div class="zc-header-column column-3">
              <span>Role</span>
            </div>
            <div class="zc-header-column column-2">
              <span>Email</span>
            </div>
            <div class="zc-header-column column-3">
              <span>Status</span>
            </div>
            <div class="zc-header-column column-4">
              <span>Date created</span>
            </div>
            <div class="zc-header-column column-5">
              <span>Actions</span>
            </div>
          </div>
        </div>
        <div class="zc-content-table users-list">
          <div class="zc-content-row" *ngFor="let user of usersList">
            <div class="zc-content-column">
              <span>{{user.token ? '/' : (user.first_name + ' ' + user.last_name)}}</span>
            </div>

            <div class="zc-content-column column-3">
              <span class="capitalize-text">{{user.role ?  user.role : 'member'}}</span>
            </div>
            <div class="zc-content-column column-2">
              <span>{{user.email}}</span>
            </div>
            <div class="zc-content-column column-3">
              <div class="wrapper-status" [ngClass]="user.token ? 'pending' : 'completed'">
                <span>{{user.token ? 'pending' : 'active'}}</span>
              </div>
            </div>
            <div class="zc-content-column column-4">
              <span>{{user.created_at ? (user.created_at | date: 'dd.MM.yyyy') : '/'}}</span>
            </div>
            <div class="zc-content-column column-4">
              <div class="buttons-history">
                <div class="wrapper-button-history"(click)="userForDelete = user">
                  <img src="../../../../assets/icons/icon-trash.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <wx-invite-member-modal
    @fadeInOutAnimation
    *ngIf="isInviteNewMemberModalOpen"
    (cancelModal)="isInviteNewMemberModalOpen = false"
    (confirmModal)="onConfirmInviteMember($event)"
  ></wx-invite-member-modal>

    <wx-confirm-modal
      *ngIf="userForDelete !== null"
      [portfolioName]="userForDelete?.first_name + ' ' + userForDelete?.last_name"
      [type]="'user'"
      (cancelModal)="userForDelete = null"
      (confirmModal)="onConfirmDeleteUser()"
    ></wx-confirm-modal>
</div>
