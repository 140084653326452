<div class="grid mobile-grid">
  <div class="grid__m-12">
    <div class="zc-table zc-table--reverse">
      <div class="zc-header-table">
        <div class="zc-header-row">
          <div class="zc-header-column">
            <span>YEAR</span>
          </div>
          <div *ngFor="let balance of balanceSheet" class="zc-header-column">
            <span>{{balance.calendarYear}} {{balance.period}}</span>
          </div>
        </div>
      </div>
      <div class="zc-content-table">
        <div class="zc-content-column">
          <div class="zc-content-row">
            <span>Total Assets</span>
          </div>
          <div class="zc-content-row">
            <span>Total Current Assets</span>
          </div>
          <div class="zc-content-row">
            <span>Cash And Short Term Investments</span>
          </div>
          <div class="zc-content-row">
            <span>Cash And Cash Equivalents </span>
          </div>
          <div class="zc-content-row">
            <span>Short Term Investments</span>
          </div>
          <div class="zc-content-row">
            <span>Receivables</span>
          </div>
          <div class="zc-content-row">
            <span>Inventory</span>
          </div>
          <div class="zc-content-row">
            <span>Other Current Assets</span>
          </div>
          <div class="zc-content-row">
            <span>Total Non-current Assets</span>
          </div>
          <div class="zc-content-row">
            <span>Property, Plant & Equipment Net</span>
          </div>
          <div class="zc-content-row">
            <span>Long Term Investments</span>
          </div>
          <div class="zc-content-row">
            <span>Other Non-current Assets</span>
          </div>
          <div class="zc-content-row">
            <span>Total Liabilities </span>
          </div>
          <div class="zc-content-row">
            <span>Total Current Liabilities</span>
          </div>
          <div class="zc-content-row">
            <span>Payables</span>
          </div>
          <div class="zc-content-row">
            <span>Short Term Debt</span>
          </div>
          <div class="zc-content-row">
            <span>Deferred Revenue</span>
          </div>
          <div class="zc-content-row">
            <span>Other Current Liabilities</span>
          </div>
          <div class="zc-content-row">
            <span>Total Non-current Liabilities</span>
          </div>
          <div class="zc-content-row">
            <span>Long Term Debt</span>
          </div>
          <div class="zc-content-row">
            <span>Other Non-current Liabilities</span>
          </div>
          <div class="zc-content-row">
            <span>Total Shareholders Equity </span>
          </div>
          <div class="zc-content-row">
            <span>Common Stock</span>
          </div>
          <div class="zc-content-row">
            <span>Retained Earnings</span>
          </div>
          <div class="zc-content-row">
            <span>Accumulated Other Comprehensive Income Loss</span>
          </div>
          <div class="zc-content-row">
            <span>Total Debt</span>
          </div>
          <div class="zc-content-row">
            <span>Total Investments</span>
          </div>
          <div class="zc-content-row">
            <span>Net Debt </span>
          </div>
        </div>

        <div *ngFor="let sheet of balanceSheet" class="zc-content-column">
          <div class="zc-content-row">
            <span>{{ sheet.totalAssets | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalCurrentAssets | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.cashAndShortTermInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.cashAndCashEquivalents | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.shortTermInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.netReceivables | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.inventory | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.otherCurrentAssets | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalNonCurrentAssets | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.propertyPlantEquipmentNet | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.longTermInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.otherNonCurrentAssets | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalLiabilities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalCurrentLiabilities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.accountPayables | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.shortTermDebt | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.deferredRevenue | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.otherCurrentLiabilities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalNonCurrentLiabilities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.longTermDebt | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.otherNonCurrentLiabilities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalStockholdersEquity | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.commonStock | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.retainedEarnings | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.accumulatedOtherComprehensiveIncomeLoss | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalDebt | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.totalInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ sheet.netDebt | number:'1.0':'en-US' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
