import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {UiHelperService} from '../../services/helpers/ui-helper.service';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {InputDebounceTime} from "../../enums/general-enums";
import {Subject} from "rxjs";

@Component({
  selector: 'wx-dropdown-select',
  templateUrl: './dropdown-select.component.html',
})
export class DropdownSelectComponent implements OnInit{
  @Input() items: Array<any> = [];
  @Input() propForView = 'name';
  @Input() propIdentifier: string | number = '';
  @Input() disableSelect = false;
  @Input() hasSearch = false;
  @Input() placeholderBtn = 'Select client';
  @Output() itemSelected = new EventEmitter<any>();
  @Output() closeDropdown = new EventEmitter<true>();
  @ViewChild('dropdownBtn') dropdownBtn: ElementRef | undefined;
  searchQuery= '';
  dropdownOpened = false;
  selectedItem: any;
  filteredItems: Array<any> = [];

  constructor(private renderer: Renderer2,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    this.filteredItems = this.items;
    setTimeout(() => {
      this.listenToClickDropdown();
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    if (this.dropdownOpened && this.hasSearch) {
      if (/^[a-zA-Z]$/.test(event.key)) {
        this.searchQuery += event.key;
      } else if (event.key === 'Backspace') {
        this.searchQuery = this.searchQuery.slice(0, -1);
      }
      this.filteredItems = this.searchQuery
        ? this.items.filter(item => item[this.propForView].toLowerCase().includes(this.searchQuery.toLowerCase()))
        : this.items;
    }
  }

  toggleDropdown(event: Event): void {
    event.stopImmediatePropagation();
    this.dropdownOpened = !this.dropdownOpened;
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownBtn, this.dropdownBtn, e)) {
        this.dropdownOpened = false;
        this.searchQuery = '';
        this.filteredItems = this.items;
        this.closeDropdown.emit(true);
      }
    });
  }

  selectItem(event: any, item: any): void {
    event.stopPropagation();
    this.selectedItem = item;
    this.searchQuery = '';
    this.filteredItems = this.items;
    this.dropdownOpened = false;
    this.itemSelected.emit(item);
  }
}
