import {Component, OnInit} from '@angular/core';
import {DocumentsHttpService} from '../../../services/http-services/documents/documents-http.service';
import {ActivatedRoute} from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'wx-single-document',
  templateUrl: './single-document.component.html',
})
export class SingleDocumentComponent implements OnInit {
  documentId: any;
  document: any;
  documentPreviewUrl: SafeResourceUrl | null = null;
  constructor(private documentsHttpService: DocumentsHttpService,
              private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer) {}

ngOnInit(): void {
  this.activatedRoute.params.subscribe((res: any) => {
    this.documentId = res.id;
    if (this.documentId) {
      this.getDocument();
      this.getDocumentPreview();
    }
  });
}
  getDocument(): void {
    this.documentsHttpService.getDocument({id: this.documentId}).then(res => {
      this.document = res;
    });
  }
  getDocumentPreview(): void {
    this.documentsHttpService.getDocumentPreview({id: this.documentId}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      this.documentPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
}
