<div class="wrapper-page settings">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <span class="title-page">Settings</span>
      </div>
      <div class="header-content-page-right">
        <button class="zc-btn zc-btn--delete" (click)="isCancelSubscriptionModalOpen = true">
          <span>Cancel subscription</span>
        </button>
        <button class="zc-btn zc-btn--primary" (click)="isResumeSubscriptionModalOpen = true">
          <span>Resume subscription</span>
        </button>
      </div>
    </div>
    <div class="grid mobile-grid gird-gap-16">
      <div class="grid__m-12">
        <form class="grid mobile-grid" (submit)="handleStripeForm($event)">
          <div class="grid__l-6">
            <span class="active-card-title">Profile information:</span>
            <div class="payment-wrapper">
              <div class="card-stripe settings">
                <div id="address-element"></div>
              </div>
            </div>
          </div>
          <div class="grid__l-6 payment-wrapper">
            <span class="active-card-title">Active card data:</span>
              <div class="card-stripe settings">
                <div id="card-number-element"></div>
                <div id="card-expiry-element"></div>
                <div id="card-cvc-element"></div>
              </div>
              <div class="wrapper-button-stripe">
                <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
                  <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
                  <span>Update</span>
                </button>
              </div>
          </div>
        </form>
      </div>
    </div>
    <div class="grid mobile-grid gird-gap-16">
      <div class="grid__m-12">
        <div class="grid__m-12">
          <span class="active-card-title">Invoices</span>
        </div>
      </div>
      <div class="grid__l-6 grid__xl-4 wrapper-invoices" *ngFor="let invoice of invoices">
        <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Amount:
          </span>
          <span class="value-info-invoice">
            {{invoice.amount}}
            <span>{{invoice.currency}}</span>
          </span>
        </div>
        <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Description:
          </span>
          <span class="value-info-invoice">
            {{invoice.description}}
          </span>
        </div>
        <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Paid at:
          </span>
          <span class="value-info-invoice">
            {{invoice.paid_at | date: 'dd.MM.yyyy'}}
          </span>
        </div>
        <a class="download-invoice" [href]="invoice.download_url">
          <i class="fa-solid fa-download"></i>
          <span target="_blank">Download Invoice</span>
        </a>
      </div>
    </div>
  </div>
</div>
<wx-cancel-subscription-modal
  *ngIf="isCancelSubscriptionModalOpen"
  (cancelModal)="isCancelSubscriptionModalOpen = false"
  (confirmModal)="onConfirmCancelSubscription()"
></wx-cancel-subscription-modal>

<wx-resume-subscription-modal
  *ngIf="isResumeSubscriptionModalOpen"
  (cancelModal)="isResumeSubscriptionModalOpen = false"
  (confirmModal)="onConfirmResumeSubscription()"
></wx-resume-subscription-modal>
