import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'returnFmpPricePipe',
  pure: true
})
export class ReturnFmpPricePipe implements PipeTransform {
  transform(value: string, pdfFmpCompanyPrices: any, isInitialDate: boolean, typeForReturn: string = 'string'): string | number {
    return isInitialDate && pdfFmpCompanyPrices[value]?.length
      ? pdfFmpCompanyPrices[value][0].price
      : (Object.keys(pdfFmpCompanyPrices).length)
        ? pdfFmpCompanyPrices[value.toLowerCase()] && pdfFmpCompanyPrices[value.toLowerCase()]['price_from_pdf']?.length
          ? pdfFmpCompanyPrices[value.toLowerCase()]['price_from_pdf'][0].close
          : typeForReturn === 'string' ? 'N/A' : 0
        : typeForReturn === 'string' ? 'N/A' : 0;
  }
}
