import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {SingleDocumentComponent} from './single-document.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singleDocument.pageInRouting,
    data: {data: PAGES_AND_PATHS.singleDocument},
    component: SingleDocumentComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-document.module').then(m => m.SingleDocumentModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SingleDocumentRoutingModule { }
