export enum LocalStorageProperties {
    token = 'wx-token',
    user = 'wx-user',
    role = 'wx-role'
}

export enum InputDebounceTime {
  time = 600
}

export enum RoleEnums {
  member = 'Member',
  owner = 'Owner'
}
