<div class="wrapper-sidebar">
  <div class="header-sidebar">
    <div class="wrapper-logo">
      <img src="../assets/images/logo-sidebar.svg" alt="">
    </div>
  </div>
  <div class="content-sidebar">
    <!--      Dodajemo klasu "active"      -->
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-dashboard.svg" alt="">
        <span>Dashboard</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-clients.svg" alt="">
        <span>Clients</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-forms.svg" alt="">
        <span>Questionnaires</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-workspaces.svg" alt="">
        <span>Custodians</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item" [routerLink]="[PAGES_AND_PATHS.portfolio.pagePath]">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-portfolio.svg" alt="">
        <span>Portfolio</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-quotes.svg" alt="">
        <span>Invoicing</span>
      </div>
    </div>
    <hr>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-chat.svg" alt="">
        <span>Chat & Talk</span>
      </div>
    </div>
    <div class="wrapper-sidebar-item">
      <div class="sidebar-item">
        <img src="../assets/icons/icon-folders.svg" alt="">
        <span>Documents</span>
      </div>
    </div>
  </div>
  <div class="footer-sidebar">
    <div class="wrapper-sidebar-item-settings">
      <div class="wrapper-sidebar-item">
        <div class="sidebar-item">
          <img src="../assets/icons/icon-settings.svg" alt="">
          <span>Settings</span>
        </div>
      </div>
    </div>
    <hr>
    <div class="wrapper-profile">
      <div class="zc-d-flex zc-align-items">
        <div class="wrapper-profile-avatar">
          <img src="../assets/images/avatar.png" alt="">
        </div>
        <div class="wrapper-text">
          <span class="name">David Smith</span>
          <span class="email">{{'david.s@connectwealth.com'}}</span>
        </div>
      </div>
      <div class="wrapper-logout">
        <img (click)="authHelperService.clientLogout(PAGES_AND_PATHS.login.pagePath)" src="../assets/icons/icon-logout.svg" alt="">
      </div>
    </div>
  </div>
</div>
