import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';

const USERS_ROUTES = {
  GET_ALL_MEMBERS: 'members',
  POST_INVITE_MEMBER: 'invite/members',
  DELETE_MEMBER: 'members/:id',
  DELETE_INVITED_MEMBER: 'invite/:token'
};

@Injectable({
  providedIn: 'root'
})
export class UsersHttpService {
  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {}

  getMembersReq(): Observable<any> {
    return this.httpClient.get(USERS_ROUTES.GET_ALL_MEMBERS) as any;
  }
  getMembers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getMembersReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  postInviteMemberReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(USERS_ROUTES.POST_INVITE_MEMBER, bodyParams) as any;
  }
  postInviteMember(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postInviteMemberReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  deleteMemberReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(USERS_ROUTES.DELETE_MEMBER, urlParams)
    return this.httpClient.delete(url) as any;
  }
  deleteMember(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteMemberReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  deleteInvitedMemberReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(USERS_ROUTES.DELETE_INVITED_MEMBER, urlParams)
    return this.httpClient.delete(url) as any;
  }
  deleteInvitedMember(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteInvitedMemberReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
