import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WxPagination} from '../../interfaces/general-interfaces';

@Component({
  selector: 'wx-pagination',
  templateUrl: './pagination.component.html',
  styles: [
  ]
})
export class PaginationComponent implements OnInit, WxPagination {

  @Input() totalItems: number | undefined;
  @Input() currentPage: number | undefined;
  @Input() perPage: number | undefined;
  @Input() collection: any[] = [];
  @Input() from: number | undefined;
  @Input() to: number | undefined;
  @Input() pagId: undefined | string;
  @Input() paginationClass?: string;
  @Output() pageChanged = new EventEmitter<any>();
  nextPageUrl: string | undefined;
  lastPage!: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  onPageChanged(event: number): void {
    this.pageChanged.emit(event);
  }

}
