import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SideMenuComponent} from './side-menu.component';
import {RouterLink} from "@angular/router";



@NgModule({
  declarations: [SideMenuComponent],
    imports: [
        CommonModule,
        RouterLink
    ],
  exports: [
    SideMenuComponent
  ]
})
export class SideMenuModule { }
