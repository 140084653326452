<div>
  <div class="ff-dropdown open xs-mb-0">
    <button class="btn-dropdown"
      #dropdownBtn
      type="button"
      (click)="toggleDropdown($event)"
      [disabled]="disableSelect">
      <div *ngIf="!selectedItems.length" class=""><span>Filter by: Instrument Type</span></div>
      <div class="selected-item" *ngIf="selectedItems.length">
        <div class="wrapper-item-selected" *ngFor="let selectedItem of selectedItems | slice: 0: 2">
          <span>{{selectedItem}}</span>
          <img class="img-close" (click)="selectItem($event, selectedItem)" src="../../../assets/icons/icon-close-modal.svg" alt="">
        </div>
        <div *ngIf="selectedItems.length > 2" class="wrapper-item-selected">
          <span>+{{selectedItems.length - 2}}</span>
        </div>
      </div>
      <img [ngClass]="{'rotate-icon': dropdownOpened}" src="../../../assets/icons/icon-arrow-left-paggination.svg" alt="">
    </button>
    <div class="dropdown-items"
      #dropdownMenu
      *ngIf="dropdownOpened && items.length">
      <button class="item"
        type="button"
        *ngFor="let item of items"
        [disabled]="disableSelect"
        (click)="selectItem($event, item)"
        [ngClass]="{'active': selectedItems.includes(item)}">
        <span>
          {{item}}
        </span>
        <img style="width: 16px;" src="../../../assets/icons/check.png" alt="">
      </button>
    </div>
  </div>
</div>

