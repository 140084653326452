import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {UiHelperService} from '../../services/helpers/ui-helper.service';

@Component({
  selector: 'wx-dropdown-multi-select',
  templateUrl: './dropdown-multi-select.component.html',
})
export class DropdownMultiSelectComponent implements OnInit{
  @Input() items: Array<any> = [];
  @Input() disableSelect = false;
  @Output() itemSelected = new EventEmitter<any>();
  @Output() closeDropdown = new EventEmitter<true>();
  @ViewChild('dropdownBtn') dropdownBtn: ElementRef | undefined;
  dropdownOpened = false;
  selectedItems: any[] = [];

  constructor(private renderer: Renderer2,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.listenToClickDropdown();
    });
  }

  toggleDropdown(event: Event): void {
    event.stopImmediatePropagation();
    this.dropdownOpened = !this.dropdownOpened;
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownBtn, this.dropdownBtn, e)) {
        this.dropdownOpened = false;
        this.closeDropdown.emit(true);
      }
    });
  }

  selectItem(event: any, item: any): void {
    event.stopPropagation();
    (this.selectedItems.some(element => element === item))
      ? this.selectedItems = this.selectedItems.filter(element => element !== item)
      : this.selectedItems.push(item);
    this.itemSelected.emit(this.selectedItems);
  }
}
