import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {UsersListComponent} from './users-list.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.userList.pageInRouting,
    data: {data: PAGES_AND_PATHS.userList},
    component: UsersListComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./users-list.module').then(m => m.UsersListModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersListRoutingModule { }
