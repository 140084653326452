import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capitalizeStringPipe',
})
export class CapitalizeStringPipe implements PipeTransform {
  transform(value: string): string {
    return (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ');
  }
}
