import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {environment} from '../../../../environments/environment';
import {AuthenticationHelperService} from '../../helpers/authentication-helper.service';

const DOCUMENTS_API_ROUTES = {
  GET_DOCUMENTS: 'documents',
  GET_DOCUMENT: 'documents/:id',
  POST_DOCUMENT: 'documents',
  DELETE_DOCUMENT: 'documents/:id',
  GET_DOCUMENT_PREVIEW: 'documents/:id/preview',
  POST_SEND_QUESTION: 'documents/ask',
  POST_FILE_PREVIEW: 'documents/file-preview',
  POST_DOCUMENT_CHAT_RESET: 'documents/reset'
};
@Injectable({
  providedIn: 'root'
})
export class DocumentsHttpService {

  constructor(private httpClient: HttpClient,
              private authHelper: AuthenticationHelperService,
              private generalHttpHelperService: GeneralHttpHelperService) { }


  // GET DOCUMENTS START
  private getDocumentsReq(): Observable<any> {
    return this.httpClient.get(DOCUMENTS_API_ROUTES.GET_DOCUMENTS);
  }

  getDocuments(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDocumentsReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // GET DOCUMENTS END
  // GET DOCUMENT START
  private getDocumentReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(DOCUMENTS_API_ROUTES.GET_DOCUMENT, urlParams);
    return this.httpClient.get(url);
  }

  getDocument(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDocumentReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // GET DOCUMENT END
  // GET DOCUMENT PREVIEW START
  private getDocumentPreviewReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(DOCUMENTS_API_ROUTES.GET_DOCUMENT_PREVIEW, urlParams);
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getDocumentPreview(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDocumentPreviewReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // GET DOCUMENT PREVIEW END

  // POST CREATE DOCUMENT START
  private postCreateDocumentReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(DOCUMENTS_API_ROUTES.POST_DOCUMENT, bodyParams);
  }

  postCreateDocument(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postCreateDocumentReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST CREATE DOCUMENT END

  // POST DELETE DOCUMENT START
  private deleteDocumentReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(DOCUMENTS_API_ROUTES.DELETE_DOCUMENT, urlParams);
    return this.httpClient.delete(url);
  }

  deleteDocument(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteDocumentReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST CREATE DOCUMENTS END

  // POST SEND QUESTION START
  private postSendQuestionReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(DOCUMENTS_API_ROUTES.POST_SEND_QUESTION, bodyParams);
  }

  postSendQuestion(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postSendQuestionReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST SEND QUESTION END

  // POST CHAT RESET START
  private postChatResetReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(DOCUMENTS_API_ROUTES.POST_DOCUMENT_CHAT_RESET, bodyParams);
  }

  postChatReset(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postChatResetReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST CHAT RESET END

  // POST FILE PREVIEW START
  private postFilePreviewReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(DOCUMENTS_API_ROUTES.POST_FILE_PREVIEW, bodyParams);
  }

  postFilePreview(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postFilePreviewReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST FILE PREVIEW END

  private downloadFileReq(imagePath: string | null | undefined, callback: any, err?: any): void {
    const xhr = new XMLHttpRequest();
    xhr.onload = (e) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        if (xhr.status === 500 || xhr.status === 404) {
          callback(null);
        } else {
          callback(reader.result);
        }
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('POST', environment.API_ENDPOINT + DOCUMENTS_API_ROUTES.POST_FILE_PREVIEW);
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.authHelper.getToken);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.responseType = 'blob';
    const body = {path: imagePath};
    try {
      xhr.send(JSON.stringify(body));
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  downloadFile(imagePath: string | null | undefined, imageName: string | undefined): void {
    this.downloadFileReq(imagePath, (cb: any) => {
      const download = document.createElement('a');
      download.href = cb;
      if (imageName) {
        download.download = imageName;
      }
      download.click();
    });
  }

  returnBase64FromImage(imagePath: string | undefined): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.downloadFileReq(imagePath, (cb: any) => {
          resolve(cb);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  getFilePreview(bodyParam: any): void {
    this.postFilePreview(bodyParam).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
  }
}
