import {Component, HostListener} from '@angular/core';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {AuthenticationHttpService} from "../../services/http-services/auth/authentication-http.service";

@Component({
  selector: 'wx-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  isScrolledFromTop = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(public authHelperService: AuthenticationHelperService) {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    if (!this.isScrolledFromTop && window.scrollY > 0) {
      this.isScrolledFromTop = true;
    }
    if (this.isScrolledFromTop && window.scrollY === 0) {
      this.isScrolledFromTop = false;
    }
  }
}
