import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'returnPLPipe',
})
export class ReturnPLPipe implements PipeTransform {
  transform(value: string, additionalParams: {
    type: string,
    quantity: string,
    currentFmpPrice: any
  }, typeForReturn: string = 'string'): any {
    return (additionalParams.type.toLowerCase() === 'equities' || additionalParams.type.toLowerCase() === 'etf' || additionalParams.type.toLowerCase() === 'equity')
      ? (!additionalParams.quantity || additionalParams.quantity === '0.00' || !value || value === '0.00' || additionalParams.currentFmpPrice === 0)
        ? typeForReturn === 'string'
          ? 'N/A'
          : 0
        : (additionalParams.currentFmpPrice * Number(additionalParams.quantity.toString().replace(/[\s-$%',a-zA-Z]+/g, '')) - Number(value.toString().replace(/[\s-$%',a-zA-Z]+/g, '')))
      : typeForReturn === 'string'
        ? 'N/A'
        : 0;
  }
}

