import {Component, OnInit} from '@angular/core';
import {ActivePageHelperService} from './services/helpers/active-page-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(public activePageHelper: ActivePageHelperService) {
  }
  ngOnInit(): void {}
}
