import {Injectable} from '@angular/core';
import {ChartOptions} from '../../types/chart-types';

@Injectable({
  providedIn: 'root'
})
export class ChartHelperService {

  formatLargeNumberLabel(value: number): string {
    if (Math.abs(value) >= 1.0e+9) {
      return (Math.abs(value) / 1.0e+9).toFixed(1) + 'B';
    }
    if (Math.abs(value) >= 1.0e+6) {
      return (Math.abs(value) / 1.0e+6).toFixed(1) + 'M';
    }
    if (Math.abs(value) >= 1.0e+3) {
      return (Math.abs(value) / 1.0e+3).toFixed(1) + 'K';
    }
    return value.toFixed(0);
  }

  formatterYearQuarterLabel(value: any, timestamp: any): string {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based in JS
    let quarter;
    if (month <= 3) {
      quarter = 'Q1';
    } else if (month <= 6) {
      quarter = 'Q2';
    } else if (month <= 9) {
      quarter = 'Q3';
    } else {
      quarter = 'Q4';
    }
    return `${year} ${quarter}`;
  }

  returnChartPeriodFromDate(toDate: string | number, howEarlier: number, type: string): any {
    const date = new Date(toDate);
    switch (type) {
      case 'day':
        date.setDate(date.getDate() - howEarlier);
        break;
      case 'month':
        date.setMonth(date.getMonth() - howEarlier);
        break;
      case 'year':
        date.setFullYear(date.getFullYear() - howEarlier);
    }
    const options: Intl.DateTimeFormatOptions = {year: "numeric", month: "2-digit", day: "2-digit"};
    const [day, month, year] = date.toLocaleDateString('en', options).split(',')[0].split('/');
    return `${year}-${day}-${month}`;
  }

  returnHistoricalPriceAreaChartOptions(chartData: any): ChartOptions {
    return {
      series: chartData.series,
      chart: {
        type: 'area',
        width: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#9E94E6', '#7CA5E1'],
        curve: 'straight',
        width: 2,
      },
      markers: {
        colors: ['#9E94E6', '#7CA5E1'],
      },
      tooltip: {
        marker: {
          fillColors: ['#9E94E6', '#7CA5E1']
        }
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#9E94E6', '#7CA5E1'],
        }
      },
      fill: {
        colors: ['#9E94E6', '#7CA5E1'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.95,
          opacityTo: 0.75,
          stops: [0, 100]
        }
      },
      labels: chartData.labels,
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: chartData.tickAmount,
        labels: {
          formatter(val: number): string {
            return val.toFixed(2);
          }
        }
      }
    };
  }

  returnFinancialStatementAreaChartOptions(chartData: any): ChartOptions {
    return {
      series: chartData.series,
      chart: {
        type: 'area',
        width: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#11BB8D', '#656CE1'],
        curve: 'straight',
        width: 2,
      },
      markers: {
        colors: ['#11BB8D', '#656CE1'],
      },
      tooltip: {
        marker: {
          fillColors: ['#11BB8D', '#656CE1']
        }
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#11BB8D', '#656CE1'],
        }
      },
      fill: {
        colors: ['#11BB8D', '#656CE1'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.8,
          opacityTo: 0.6,
          stops: [0, 100]
        }
      },
      labels: chartData.labels,
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          formatter: (value: any, timestamp: any): any => {
            return this.formatterYearQuarterLabel(value, timestamp);
          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: chartData.tickAmount,
        labels: {
          formatter: (value: any) => {
            return this.formatLargeNumberLabel(value);
          }
        }
      }
    };
  }

  returnMonthlyPerformanceAreaChartOptions(chartData: any, highlightSeriesCallback: (seriesIndex: number) => void): ChartOptions {
    return {
      series: chartData.series,
      chart: {
        type: 'area',
        width: '100%',
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          legendClick: (chartContext: any, seriesIndex: number) => {
            highlightSeriesCallback(seriesIndex);
          }
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#9E94E6', '#11BB8D', '#EBA834'],
        curve: 'straight',
        width: 2,
      },
      markers: {
        colors: ['#9E94E6', '#11BB8D', '#EBA834'],
      },
      tooltip: {
        marker: {
          fillColors: ['#9E94E6', '#11BB8D', '#EBA834']
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        markers: {
          fillColors: ['#9E94E6', '#11BB8D', '#EBA834'],
        }
      },
      fill: {
        colors: ['#9E94E6', '#11BB8D', '#EBA834'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.95,
          opacityTo: 0.75,
          stops: [0, 100]
        }
      },
      xaxis: {
        type: 'datetime',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: chartData.tickAmount,
        labels: {
          formatter(val: number): string {
            return val + '%';
          }
        }
      },
    };
  }

  handleChartClick(event: MouseEvent, chartContext: any, config: any) {
    const legendItems = chartContext.el.querySelectorAll('.apexcharts-legend-series');
    legendItems.forEach((item: any, index: number) => {
      if (item.contains(event.target as Node)) {
        chartContext.chart.toggleSeries(0);
      }
    });
  }

  returnHistoricalPriceBarChartOptions(chartData: any): ChartOptions {
    return {
      series: chartData.series,
      chart: {
        width: '100%',
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#6966E9'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: '#f5f5f5'
      },
      labels: chartData.labels,
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        type: 'linear',
        min: 0,
        tickAmount: chartData.tickAmount,
        labels: {
          formatter: (value: any) => {
            return value.toFixed(2);
          }
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          columnWidth: '35%',
        },
      },
    };
  }

  returnEarningBarChartOptions(chartData: any): ChartOptions {
    return {
      series: chartData.series,
      chart: {
        width: '100%',
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#6966E9', '#40E0F8', '#FFBB36', '#00C08B'],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          formatter: (value: string) => {
            return value;
          }
        }
      },
      grid: {
        borderColor: '#f5f5f5'
      },
      xaxis: {
        type: 'categories',
        categories: chartData.labels,
        labels: {
          style: {
            colors: '#a5a5a5',
            fontSize: '12px',
          },
          formatter: (value: any, timestamp: any): any => {
            return this.formatterYearQuarterLabel(value, timestamp);
          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        type: 'linear',
        min: 0,
        tickAmount: chartData.tickAmount,
        labels: {
          formatter: (value: any) => {
            return this.formatLargeNumberLabel(value);
          }
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          columnWidth: '35%',
        },
      },
    };
  }
}
