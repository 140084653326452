<div class="zc-wrapper-search">
  <img src="../../../assets/icons/icon-search.svg" alt="">
  <input
    [(ngModel)]="searchQuery"
    (ngModelChange)="searchSubject.next($event)"
    type="text"
    name="search"
    autocomplete="off"
    [ngClass]="inputClasses"
    [placeholder]="searchPlaceholder"
    class="{{ inputClass }}"
  />
</div>
