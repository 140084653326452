import { Component } from '@angular/core';
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";
import {AuthenticationHelperService} from "../../services/helpers/authentication-helper.service";

@Component({
  selector: 'wx-side-menu',
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent {
  constructor(public authHelperService: AuthenticationHelperService) {
  }

  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
}
