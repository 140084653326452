import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from '../../../constants/pages-and-paths';
import { PortfolioStatementsHistoryComponent } from './portfolio-statements-history.component';
import {AuthGuardService} from "../../../guards/auth-guard.service";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.portfolioStatementsHistory.pageInRouting,
    data: {data: PAGES_AND_PATHS.portfolioStatementsHistory},
    component: PortfolioStatementsHistoryComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./portfolio-statements-history.module').then(m => m.PortfolioStatementsHistoryModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioStatementsHistoryRoutingModule { }
