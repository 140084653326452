<div class="grid mobile-grid">
  <div class="grid__m-12">
    <div class="zc-table zc-table--reverse">
      <div class="zc-header-table">
        <div class="zc-header-row">
          <div class="zc-header-column">
            <span>YEAR</span>
          </div>
          <div *ngFor="let headerItem of cashFlow" class="zc-header-column">
            <span>{{headerItem.calendarYear}} {{headerItem.period}}</span>
          </div>
        </div>
      </div>
      <div class="zc-content-table">
        <div class="zc-content-column">
          <div class="zc-content-row">
            <span>Operating Cash Flow</span>
          </div>
          <div class="zc-content-row">
            <span>Net Income</span>
          </div>
          <div class="zc-content-row">
            <span>Depreciation & Amortization</span>
          </div>
          <div class="zc-content-row">
            <span>Deferred Income Taxes</span>
          </div>
          <div class="zc-content-row">
            <span>Stock-based Compensation</span>
          </div>
          <div class="zc-content-row">
            <span>Change In Working Capital</span>
          </div>
          <div class="zc-content-row">
            <span>Other Non-cash Items </span>
          </div>
          <div class="zc-content-row">
            <span>Investing Cash Flow</span>
          </div>
          <div class="zc-content-row">
            <span>Investments In PPE</span>
          </div>
          <div class="zc-content-row">
            <span>Acquisitions</span>
          </div>
          <div class="zc-content-row">
            <span>Investment Purchases</span>
          </div>
          <div class="zc-content-row">
            <span>Sales/Maturities Of Investments</span>
          </div>
          <div class="zc-content-row">
            <span>Other Investing Activities </span>
          </div>
          <div class="zc-content-row">
            <span>Financing Cash Flow</span>
          </div>
          <div class="zc-content-row">
            <span>Debt Repayment</span>
          </div>
          <div class="zc-content-row">
            <span>Dividends Payments</span>
          </div>
          <div class="zc-content-row">
            <span>Common Stock Repurchased</span>
          </div>
          <div class="zc-content-row">
            <span>Common Stock Issuance</span>
          </div>
          <div class="zc-content-row">
            <span>Other Financing Activities</span>
          </div>
          <div class="zc-content-row">
            <span>Accounts Receivables</span>
          </div>
          <div class="zc-content-row">
            <span>Accounts Payables</span>
          </div>
          <div class="zc-content-row">
            <span>Inventory</span>
          </div>
          <div class="zc-content-row">
            <span>Other Working Capital</span>
          </div>
          <div class="zc-content-row">
            <span>Cash At Beginning Of Period</span>
          </div>
          <div class="zc-content-row">
            <span>Cash At End Of Period</span>
          </div>
          <div class="zc-content-row">
            <span>Capital Expenditure</span>
          </div>
          <div class="zc-content-row">
            <span>Net Cash flow / Change In Cash</span>
          </div>
          <div class="zc-content-row">
            <span>Free Cash Flow</span>
          </div>
        </div>
        <div *ngFor="let cash of cashFlow" class="zc-content-column">
          <div class="zc-content-row">
            <span>{{ cash.operatingCashFlow | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.netIncome | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.depreciationAndAmortization | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.deferredIncomeTax | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.stockBasedCompensation | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.changeInWorkingCapital | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.otherNonCashItems | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.netCashUsedForInvestingActivites | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.investmentsInPropertyPlantAndEquipment | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.acquisitionsNet | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.purchasesOfInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.salesMaturitiesOfInvestments | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.otherInvestingActivites | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.netCashUsedProvidedByFinancingActivities | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.debtRepayment | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.dividendsPaid | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.commonStockRepurchased | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.commonStockIssued | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.otherFinancingActivites | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.accountsReceivables | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.accountsPayables | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.inventory | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.otherWorkingCapital | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.cashAtBeginningOfPeriod | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.cashAtEndOfPeriod | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.capitalExpenditure | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.netChangeInCash | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ cash.freeCashFlow | number:'1.0':'en-US' }}</span>
          </div>
        </div>

        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Operating Cash Flow</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.operatingCashFlow}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Net Income</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.netIncome}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Net Income</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.netIncome}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Deprecation & Amortization</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.depreciationAndAmortization}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Deferred Income Taxes</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.deferredIncomeTax}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Net Income</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.netIncome}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="zc-content-row">-->
        <!--          <div class="zc-content-column">-->
        <!--            <span>Net Income</span>-->
        <!--          </div>-->
        <!--          <div *ngFor="let item of cashFlow" class="zc-content-column">-->
        <!--            <span>{{item.netIncome}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>
