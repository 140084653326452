import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChartTitles} from '../../../enums/chart-enums';
import {ChartHelperService} from '../../../services/helpers/chart-helper.service';
import {PortfolioHttpService} from '../../../services/http-services/portfolio/portfolio-http.service';
import {GeneralHelperService} from "../../../services/helpers/general-helper";

@Component({
  selector: 'wx-historical-prices-chart',
  templateUrl: './historical-prices-chart.component.html',
})
export class HistoricalPricesChartComponent implements OnInit, OnChanges {
  @Input() toDate = new Date(new Date().setHours(0, 0, 0, 0));
  @Input() ticker: any = '';
  @Output() historicalChartReqFinish: EventEmitter<void> = new EventEmitter<void>();
  activeFilter = {viewValue: '5D', value: 5, type: 'day'};
  fromDate = '';
  timeFrame = '30min';
  formattedChartData: any;
  showAreaChart = true;
  chartFilters: any = [
    {viewValue: '1D', value: 1, type: 'day'},
    {viewValue: '5D', value: 5, type: 'day'},
    {viewValue: '1M', value: 1, type: 'month'},
    {viewValue: '3M', value: 3, type: 'month'},
    {viewValue: '6M', value: 6, type: 'month'},
    {viewValue: '1Y', value: 1, type: 'year'}];


  protected readonly ChartTitles = ChartTitles;

  constructor(private chartHelperService: ChartHelperService,
              private generalHelperService: GeneralHelperService,
              private portfolioHttpService: PortfolioHttpService) {
  }

  ngOnInit(): void {
    this.fromDate = this.chartHelperService.returnChartPeriodFromDate(Date.now(), this.activeFilter.value, this.activeFilter.type);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.ticker && changes.ticker.currentValue) {
      this.getHistoricalChartData();
    }
  }

  getHistoricalChartData(): void {
    this.portfolioHttpService.getHistoricalPriceChartData({ticker: this.ticker, from: this.fromDate, to: this.generalHelperService.returnFormattedDateForFmp(this.toDate), timeframe: this.timeFrame}).then(res => {
      this.formattedData(res.data[this.ticker.toLowerCase()].historicalPrices);
      this.historicalChartReqFinish.emit();
    });
  }

  formattedData(data: any): void {
    const chartData: any = {
      series: [{
        name: 'Price',
        data: []
      }],
      labels: [],
      title: ChartTitles.historicalPrice,
      colors: ['#9E94E6', '#7CA5E1'],
      tickAmount: 3
    };
    data.forEach((data: any) => {
      const {date, close} = data;
      chartData.series[0].data.push(close);
      chartData.labels.push(date);
    });

    this.formattedChartData = chartData;
  }

  changeChartPeriod(selectedFilter: any): void {
    this.activeFilter = selectedFilter;
    switch (selectedFilter.viewValue) {
      case '1D':
        this.timeFrame = '30min';
        break;
      case '5D':
        this.timeFrame = '4hour';
        break;
      case '1M':
        this.timeFrame = '1day';
        break;
      case '3M':
        this.timeFrame = '1day';
        break;
      case '6M':
        this.timeFrame = '1week';
        break;
      case '1Y': {
        this.timeFrame = '1month';
      }
    }
    this.fromDate = this.chartHelperService.returnChartPeriodFromDate(Date.now(), selectedFilter.value, selectedFilter.type);
    this.getHistoricalChartData();
  }
}
