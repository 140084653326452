import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PortfolioHttpService} from '../../../../../../services/http-services/portfolio/portfolio-http.service';
import {ChartTitles} from '../../../../../../enums/chart-enums';

@Component({
  selector: 'wx-overview-tab',
  templateUrl: './overview-tab.component.html',
})
export class OverviewTabComponent implements OnInit, OnChanges {
  @Input() companyProfile: any;
  @Input() ratios!: Record<string, string | number>[];
  @Input() peopleAlsoWatch!: Record<string, any>;
  @Input() financialStatement: any;
  @Input() keyMetrics: any;
  @Input() portfolioData: any;
  @Output() clickOnPeopleAlsoWatchCompany: EventEmitter<string> = new EventEmitter<string>();
  isCompanyDescriptionOpen = false;
  financialStatementFormattedData: any;
  earningsData: any;
  isHistoricalChartReqFinished = false;
  filteredPeopleAlsoWatch: Record<string, any> = {};
  protected readonly ChartTitles = ChartTitles;

  constructor(private portfolioHttpService: PortfolioHttpService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.financialStatement && changes.financialStatement.currentValue) {
      this.formattedFinancialStatementData(changes.financialStatement.currentValue);
    }

    if (changes.companyProfile && changes.companyProfile.currentValue) {
      this.getEarningsChartData(changes.companyProfile.currentValue.symbol);
    }

    if (changes.peopleAlsoWatch && changes.peopleAlsoWatch.currentValue) {
      for (const key in changes.peopleAlsoWatch.currentValue) {
        if (changes.peopleAlsoWatch.currentValue[key].companyProfile.length) {
          this.filteredPeopleAlsoWatch[key] = changes.peopleAlsoWatch.currentValue[key];
        }
      }
    }
  }

  getEarningsChartData(ticker: string): void {
    this.portfolioHttpService.getEarningsChartData({ticker}).then(res => {
      this.formattedEarningData(res.data[ticker.toLowerCase()].historicalCalendar);
    });
  }

  formattedEarningData(chartData: any): void {
    const earningsData: any = {
      series: [{
        name: 'Revenue',
        data: []
      }],
      labels: [],
      title: 'Earning',
      colors: ['#9E94E6', '#7CA5E1'],
      tickAmount: 3
    };
    chartData.slice(4).forEach((data: any) => {
      const {date, revenue} = data;
      earningsData.series[0].data.push(revenue);
      earningsData.labels.push(date);
    });
    this.earningsData = earningsData;
  }

  formattedFinancialStatementData(chartData: any): void {
    const financialData: any = {
      series: [
        {
          name: 'Net Income',
          data: []
        },
        {
          name: 'Revenue',
          data: []
        }
      ],
      labels: [],
      colors: ['#9E94E6', '#7CA5E1'],
      tickAmount: 3
    };
    chartData.forEach((data: any) => {
      const {date, netIncome, revenue} = data;
      financialData.series[0].data.push(netIncome);
      financialData.series[1].data.push(revenue);
      financialData.labels.push(date);
    });
    this.financialStatementFormattedData = financialData;
  }

  clickOnPeopleAlsoWatchItem(ticker: string): void {
    this.earningsData = null;
    this.financialStatementFormattedData = null;
    this.isHistoricalChartReqFinished = false;
    this.clickOnPeopleAlsoWatchCompany.emit(ticker);
  }
}
