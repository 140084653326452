<div class="wrapper-modal">
  <div class="wrapper-content-modal wrapper-content-modal--confirm payment-modal">
    <div class="content-modal">
      <div style="width: 100%;" [hidden]="step !== 1">
        <div class="pricing-section">
          <div class="pricing">
            <div class="pricing-body">
              <div class="pricing-body-header">
                <h2>Choose a plan</h2>
                <p>Lorem ipsum description</p>
              </div>
              <div class="pricing-body-plans">

                <div class="active" id="pricing__monthly__plan">
                  <div>
                    <div class="card">
                      <div class="card-header">
                        <span class="card-title">Plan 1</span>
                        <h2 class="card-price">$10</h2>
                      </div>
                      <div class="card-body">
                        <ul>
                          <li>Lorem ipsum</li>
                          <li>Lorem ipsum</li>
                          <li>Lorem ipsum</li>
                        </ul>
                      </div>
                      <div class="card-footer">
                        <button class="zc-btn zc-btn--gray" (click)="step = 2"><span>Choose Plan</span></button>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header">
                        <span class="card-title">Plan 2</span>
                        <h2 class="card-price">$10</h2>
                      </div>
                      <div class="card-body">
                        <ul>
                          <li>Lorem ipsum</li>
                          <li>Lorem ipsum</li>
                          <li>Lorem ipsum</li>
                        </ul>
                      </div>
                      <div class="card-footer">
                        <button class="zc-btn zc-btn--primary" (click)="step = 2"><span>Choose Plan</span></button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <form [hidden]="step !== 2" [formGroup]="countryForm" (ngSubmit)="submitRegisterForm($event)" class="register-form">
        <div class="payment-wrapper">
          <wx-dropdown-select
            [items]="countries"
            [propForView]="'name'"
            [placeholderBtn]="'Select country'"
            [propIdentifier]="'code'"
            [hasSearch]="true"
            (itemSelected)="onCountrySelected($event)"
          ></wx-dropdown-select>
          <div class="card-stripe">
            <div #cardElementContainer id="card-element"></div>
            <div #cardErrors></div>
          </div>
        </div>
        <div class="button-register-steps">
          <button class="zc-btn zc-btn--gray w-100" type="button" (click)="step = 1"><span>Back to plan</span></button>
          <button [disabled]="isLoaderActive" class="zc-btn zc-btn--primary w-100 zc-position-relative" type="submit">
            <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
            <span>Pay</span>
          </button>
        </div>
      </form>
      <div [hidden]="step !== 3">
        <div class="description-confirm-modal success">
          <i class="fa-regular fa-circle-check"></i>
          <span>You successfully subscribed!</span>
        </div>
        <div class="buttons-confirm-modal">
          <button class="zc-btn zc-btn--primary zc-position-relative" (click)="this.confirmPayment.emit()">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
