<div class="historical-price-header">
  <div class="historical-price-header-tabs">
    <div
      *ngFor="let chartFilter of chartFilters"
      (click)="changeChartPeriod(chartFilter)"
      class="historical-price-header-tab"
      [ngClass]="{'active': activeFilter.viewValue === chartFilter.viewValue}">
      <span>{{chartFilter.viewValue}}</span>
    </div>
  </div>
  <div class="historical-price-buttons">
    <div class="price-btn" (click)="showAreaChart = true" [ngClass]="{'active': showAreaChart}">
      <img src="../../../../assets/icons/icon-graph-tab-white.svg" alt="">
    </div>
    <div class="price-btn"  (click)="showAreaChart = false" [ngClass]="{'active': !showAreaChart}">
      <img src="../../../../assets/icons/icon-graph-tab2-purple.svg" alt="">
    </div>
  </div>
</div>
<wx-area-chart *ngIf="showAreaChart" [data]="formattedChartData" [chartType]="ChartTitles.historicalPrice"></wx-area-chart>
<wx-bar-chart *ngIf="!showAreaChart" [data]="formattedChartData" [chartType]="ChartTitles.historicalPrice"></wx-bar-chart>
