import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from '../../../constants/pages-and-paths';
import { SinglePortfolioComponent } from './single-portfolio.component';
import {AuthGuardService} from '../../../guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singlePortfolio.pageInRouting,
    data: {data: PAGES_AND_PATHS.singlePortfolio},
    component: SinglePortfolioComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-portfolio.module').then(m => m.SinglePortfolioModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SinglePortfolioRoutingModule { }
