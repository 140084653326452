import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'wx-resume-subscription-modal',
  templateUrl: './resume-subscription-modal.component.html',
})
export class ResumeSubscriptionModalComponent {
  @Input() portfolioName = '';
  @Input() type = '';
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
}
