<div class="wrapper-login-page">
<!--  <div class="content-login-left">-->
<!--    <div class="overlay-login-image">-->
<!--      <span>PDFolio</span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="content-login-right">
    <div class="content-login">
      <div class="zc-d-flex zc-flex-column zc-align-items">
        <span class="logo-text">PDFolio</span>
        <span class="title">The smartest way to consolidate and track your investments</span>
<!--        <span class="subtitle">We'll use this information to complete your profile</span>-->
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
        <div class="wrapper-input">
          <span class="label-input">Email</span>
          <div class="zc-input">
            <input type="email" [formControl]="loginForm.controls.email | formControl" [placeholder]="'Email'">
          </div>
          <span class="error-message">{{loginForm.controls.email | formControlErrorMessages: 'Enter valid email.' : formTriedToSubmit}}</span>
        </div>
        <div class="wrapper-input">
          <span class="label-input">Password</span>
          <div class="zc-input">
            <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="loginForm.controls.password | formControl" [placeholder]="'Password'">
            <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{loginForm.controls.password | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
          <span class="error-message" *ngIf="errMessage">{{errMessage}}</span>
        </div>
        <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span>Sign In</span>
        </button>
        <div class="wrapper-link-sign-up">
          <span class="link-sign-up">Don't have an account? <a class="zc-link-label login" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.register.pagePath]">Sign up</a></span>
        </div>
        <a class="zc-link-label login" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.forgotPassword.pagePath]">Forgot password</a>
      </form>
    </div>
  </div>
</div>
