import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {GeneralHelperService} from "../../services/helpers/general-helper";
import {ChartHelperService} from "../../services/helpers/chart-helper.service";

@Component({
  selector: 'wx-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {
  @Input() datepickerLabel = '';
  @Input() defaultDate?: string;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  currentDate: Date = new Date();
  minDate: Date = new Date(this.currentDate.getFullYear() - 10, this.currentDate.getMonth(), this.currentDate.getDate());

  protected readonly Date = Date;

  constructor() {

  }
  onDateChange(date: MatDatepickerInputEvent<any, any>): void {
    this.dateSelected.emit(date.value);
  }

  filterWeeks = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return (day !== 0 && day !== 6);
  }
}
