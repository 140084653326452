import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {PortfolioComponent} from './portfolio.component';
import {AuthGuardService} from '../../guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.portfolio.pageInRouting,
    data: {data: PAGES_AND_PATHS.portfolio},
    component: PortfolioComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./portfolio.module').then(m => m.PortfolioModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioRoutingModule { }
