<div class="wrapper-modal">
  <div class="wrapper-content-modal wrapper-content-modal--confirm">
    <div class="content-modal">
      <div class="description-confirm-modal">
        <span>Are you sure you want to delete "{{portfolioName}}" {{type}}?</span>
      </div>
      <div class="buttons-confirm-modal">
        <button class="zc-btn zc-btn--gray" (click)="cancelModal.emit()"><span>Cancel</span></button>
        <button class="zc-btn zc-btn--delete" (click)="confirmModal.emit()"><span>Confirm</span></button>
      </div>
    </div>
  </div>
</div>
