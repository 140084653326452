export enum InvestmentAssetsTabs {
  overview = 'Overview',
  balanceSheet = 'Balance Sheet',
  incomeStatement = 'Income Statement',
  cashFlow = 'Cash Flow'
}

export enum UploadFileStatus {
  uploadStatements = 'Upload Statements',
  uploading = 'Uploading...',
  uploadFailed = 'Upload failed',
  processing = 'Processing...',
  uploadDocument = 'Upload document',
  uploadedDocument = 'Uploaded document',
}

export enum PortfolioStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
}

export enum DocumentStatus {
  processing = 'processing',
  completed = 'completed',
}
