<div class="wrapper-modal">
  <form [formGroup]="createDocumentForm" (ngSubmit)="submitCreateDocumentForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">New document</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Name</span>
        <div class="zc-input">
          <input type="text" [formControl]="createDocumentForm.controls.name | formControl">
        </div>
        <span class="error-message">{{createDocumentForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-upload-statements document">
        <div class="wrapper-content-upload-statements">
          <div class="header-upload-statements">
            <span class="title-modal-upload">{{UploadFileStatus.uploadedDocument}}</span>
          </div>
          <div class="content-upload-statements">
            <label
              *ngIf="uploadFileStatus === UploadFileStatus.uploadDocument"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
              class="wrapper-upload-pdf-file"
              [ngClass]="{'wrapper-upload-pdf-file--drag-over': isDragOver}"
              for="upload-pdf">
              <img src="../../../assets/icons/icon-upload-pdf.svg" alt="">
              <input type="file" accept=".pdf" id="upload-pdf" (change)="onFileChange($event)">
              <h6><span>Click to upload</span> or drag and drop</h6>
              <h6>Allowed file extensions: PDF</h6>
            </label>
            <div @fadeInAnimation *ngIf="uploadFileStatus === UploadFileStatus.uploadedDocument" class="error-pdf-file">
              <div class="wrapper-pdf-preview">
                <div class="zc-d-flex zc-align-items">
                  <img src="../../../assets/icons/icon-pdf.svg" alt="">
                  <div class="info-pdf-preview">
                    <span class="pdf-name">{{file?.name}}</span>
                    <span class="pdf-size">{{file && (file.size | formatFileSize: false)}}</span>
                  </div>
                </div>
                <div class="zc-d-flex zc-align-items">
                  <img (click)="removeFile()" class="zc-cursor-pointer" src="../../../assets/icons/icon-delete.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="cancelModal.emit()"><span>Cancel</span></button>
      <button type="submit" class="zc-btn zc-btn--primary zc-position-relative">
        <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
        <span>Confirm</span>
      </button>
    </div>
  </form>
</div>
