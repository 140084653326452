import {RoleEnums} from "../enums/general-enums";

export const PAGES_AND_PATHS = {
  login: {
    pageName: 'LOGIN',
    pageInRouting: 'login',
    pagePath: '/login',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/login']
  },
  register: {
    pageName: 'REGISTER',
    pageInRouting: 'register',
    pagePath: '/register',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/register']
  },
  registerMember: {
    pageName: 'REGISTER_MEMBER',
    pageInRouting: 'register/:token',
    pagePath: '/register',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/register']
  },
  resetPassword: {
    pageName: 'RESET_PASSWORD',
    pageInRouting: 'reset-password/:token',
    pagePath: '/reset-password',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/reset-password']
  },
  forgotPassword: {
    pageName: 'RESET_PASSWORD',
    pageInRouting: 'forgot-password',
    pagePath: '/forgot-password',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/forgot-password']
  },
  payment: {
    pageName: 'PAYMENT',
    pageInRouting: 'payment',
    pagePath: '/payment',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/payment'],
    accessRoles: [RoleEnums.owner]
  },
  portfolio: {
    pageName: 'PORTFOLIO',
    pageInRouting: 'portfolio',
    pagePath: '/portfolio',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio']
  },
  singlePortfolio: {
    pageName: 'SINGLE_PORTFOLIO',
    pageInRouting: 'portfolio/:id',
    pagePath: '/portfolio',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio']
  },
  portfolioStatementsHistory: {
    pageName: 'PORTFOLIO_STATEMENTS_HISTORY',
    pageInRouting: 'portfolio/statements-history/:id',
    pagePath: '/portfolio/statements-history',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio/statements-history']
  },
  portfolioStatementsPdfPreview: {
    pageName: 'PORTFOLIO_STATEMENTS_PDF_PREVIEW',
    pageInRouting: 'portfolio/:portfolioId/pdf-preview/:fileId',
    pagePath: '/portfolio/:portfolioId/pdf-preview/',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio/:portfolioId/pdf-preview/']
  },
  portfolioInvestmentAssets: {
    pageName: 'PORTFOLIO_INVESTMENT_ASSETS',
    pageInRouting: 'portfolio/investment-portfolio-assets/:id',
    pagePath: '/portfolio/investment-portfolio-assets',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio/investment-portfolio-assets']
  },
  singleDocument: {
    pageName: 'SINGLE_DOCUMENT',
    pageInRouting: 'portfolio/documents/:id',
    pagePath: '/portfolio/documents',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: true,
    pagePathWithoutParams: ['/portfolio/documents']
  },
  userList: {
    pageName: 'USER_LIST',
    pageInRouting: 'user-list',
    pagePath: '/user-list',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/user-list']
  },
  settings: {
    pageName: 'SETTINGS',
    pageInRouting: 'settings',
    pagePath: '/settings',
    showTab: false,
    inTab: false,
    tabIcon: null,
    showHeader: true,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/settings']
  },
};

export const DEFAULT_AUTHENTICATED_PAGE = {
  page: PAGES_AND_PATHS.portfolio
};
