import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PAGES_AND_PATHS} from './constants/pages-and-paths';
import {PortfolioRoutingModule} from './pages/portfolio/portfolio-routing.module';
import {SinglePortfolioRoutingModule} from './pages/portfolio/single-portfolio/single-portfolio-routing.module';
import {PortfolioStatementsHistoryRoutingModule} from './pages/portfolio/portfolio-statements-history/portfolio-statements-history-routing.module';
import {LoginRoutingModule} from './pages/login/login-routing.module';
import {RegisterRoutingModule} from './pages/register/register-routing.module';
import {ResetPasswordRoutingModule} from './pages/reset-password/reset-password-routing.module';
import {ForgotPasswordRoutingModule} from './pages/forgot-password/forgot-password-routing.module';
import {SingleDocumentRoutingModule} from './pages/portfolio/single-document/single-document-routing.module';
import {UsersListRoutingModule} from './pages/users-list/users-list-routing.module';
import {SettingsRoutingModule} from './pages/settings/settings-routing.module';
import {
  PortfolioStatementsPdfPreviewRoutingModule
} from "./pages/portfolio/portfolio-statements-pdf-preview/portfolio-statements-pdf-preview-routing.module";

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES_AND_PATHS.login.pagePath
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes),
      LoginRoutingModule,
      RegisterRoutingModule,
      ResetPasswordRoutingModule,
      ForgotPasswordRoutingModule,
      PortfolioRoutingModule,
      SinglePortfolioRoutingModule,
      PortfolioStatementsPdfPreviewRoutingModule,
      PortfolioStatementsHistoryRoutingModule,
      SingleDocumentRoutingModule,
      UsersListRoutingModule,
      SettingsRoutingModule,
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
