<div class="wrapper-login-page">
<!--  <div class="content-login-left">-->
<!--    <div class="overlay-login-image">-->
<!--      <span>PDFolio</span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="content-login-right">
    <div class="content-login">
      <div class="zc-d-flex zc-flex-column zc-align-items">
        <span class="logo-text">PDFolio</span>
        <span class="title">Reset password</span>
        <span class="subtitle">Enter your new password</span>
      </div>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <div class="wrapper-input">
          <span class="label-input">Password</span>
          <div class="zc-input">
            <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="resetPasswordForm.controls.password | formControl" [placeholder]="'********'">
            <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{resetPasswordForm.controls['password'] | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
        </div>
        <div class="wrapper-input">
          <span class="label-input">Confirm password</span>
          <div class="zc-input">
            <input [type]="isConfirmPasswordShow ? 'text' : 'password'" [formControl]="resetPasswordForm.controls.password_confirmation | formControl" [placeholder]="'********'">
            <i *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{resetPasswordForm.controls['password_confirmation'] | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
        </div>
        <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span>Reset password</span>
        </button>
        <a class="zc-link-label" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Sign in</a>
      </form>
    </div>
  </div>
</div>
