import {Component, Input} from '@angular/core';

@Component({
  selector: 'wx-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  @Input() isButtonLoader = false;

}
