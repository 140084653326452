import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({
  name: 'formControlErrorMessages',
  pure: false
})
export class FormControlErrorMessagesPipe implements PipeTransform {
  transform(formControl: AbstractControl, customPatternMsg: string | null, submittedForm: boolean | undefined, ...args: unknown[]): string {
    let isRequired: any;
    if (formControl.validator) {
      isRequired = formControl.validator({} as AbstractControl);
    }
    if (!formControl.value && submittedForm && isRequired) {
      return 'This field is required.';
    } else if (formControl.dirty && formControl.touched && formControl.invalid && !formControl.value && isRequired){
      return 'This field is required.';
    } else if (formControl.dirty && formControl.touched && formControl.invalid && formControl.errors?.minlength) {
      return 'Minimum ' + formControl.errors?.minlength.requiredLength + ' characters.';
    } else if (customPatternMsg && formControl.dirty && formControl.touched && formControl.invalid && formControl.errors?.pattern) {
      return customPatternMsg;
    } else if (formControl.dirty && formControl.touched && formControl.invalid && formControl.errors?.email) {
        return 'Enter a valid email.';
    } else {
      return '';
    }
  }
}

