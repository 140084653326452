<div *ngIf="chartOptions">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [legend]="chartOptions.legend"
    [colors]="chartOptions.colors"
    [labels]="chartOptions.labels ? chartOptions.labels : undefined"
    [tooltip]="chartOptions.tooltip"
    [title]="chartOptions.title ? chartOptions.title : undefined"
    [grid]="chartOptions.grid"
    [fill]="chartOptions.fill"
  ></apx-chart>
</div>
