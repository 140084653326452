<div class="pie-chart-wrapper" *ngIf="chartOptions">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [dataLabels]="chartOptions.dataLabels"
    [colors]="chartOptions.colors"
    [states]="chartOptions.states"
    [legend]="chartOptions.legend"
    [title]="chartOptions.title"
    [tooltip]="chartOptions.tooltip"
    [plotOptions]="chartOptions.plotOptions"
  ></apx-chart>
</div>
