<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="grid">
      <div class="grid__l-6">
        <wx-document-preview
          [documentUrl]="documentPreviewUrl"
        ></wx-document-preview>
      </div>
      <div class="grid__l-6">
        <wx-document-chat
          [documentData]="document"
        ></wx-document-chat>
      </div>
    </div>
  </div>
</div>
