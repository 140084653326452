import {trigger, style, transition, animate} from '@angular/animations';

export const fadeInAnimation = [
    trigger('fadeInAnimation', [
        transition('void => *', [
            style({opacity: 0}),
            animate(400, style({opacity: 1}))
        ]),
    ])
];
