import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortfolioHelperService {
  returnPL(value: number, additionalParams: {
    quantity: string,
    currentFmpPrice: any
  }): any {
    return (!additionalParams.quantity || additionalParams.quantity === '0.00' || !value || value === 0.00 || additionalParams.currentFmpPrice === 0)
      ? 0
      : ((additionalParams.currentFmpPrice - value) * Number(additionalParams.quantity.toString().replace(/[\s-$%',a-zA-Z]+/g, '')));
  }


  returnFmpPrice(value: string, pdfFmpCompanyPrices: any, isInitialDate: boolean): number {
    return isInitialDate && pdfFmpCompanyPrices[value].length
      ? pdfFmpCompanyPrices[value][0].price
      : (Object.keys(pdfFmpCompanyPrices).length)
        ? pdfFmpCompanyPrices[value.toLowerCase()] && pdfFmpCompanyPrices[value.toLowerCase()].price_from_pdf?.length
          ? pdfFmpCompanyPrices[value.toLowerCase()].price_from_pdf[0].close
          : 0
        : 0;
  }
}
