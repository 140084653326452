<div class="grid mobile-grid">
  <div class="grid__m-12">
    <div class="zc-table zc-table--reverse">
      <div class="zc-header-table">
        <div class="zc-header-row">
          <div class="zc-header-column">
            <span>YEAR</span>
          </div>
          <div *ngFor="let income of incomeStatements" class="zc-header-column">
            <span>{{income.calendarYear}} {{income.period}}</span>
          </div>
        </div>
      </div>
      <div class="zc-content-table">
        <div class="zc-content-column">
          <div class="zc-content-row">
            <span>Revenue</span>
          </div>
          <div class="zc-content-row">
            <span>Cost Of Revenue</span>
          </div>
          <div class="zc-content-row">
            <span>Gross Profit</span>
          </div>
          <div class="zc-content-row">
            <span>Operating Expenses</span>
          </div>
          <div class="zc-content-row">
            <span>Selling, General And Administrative Expenses</span>
          </div>
          <div class="zc-content-row">
            <span>Research And Development Expenses</span>
          </div>
          <div class="zc-content-row">
            <span>Cost And Expenses</span>
          </div>
          <div class="zc-content-row">
            <span>Operating Income</span>
          </div>
          <div class="zc-content-row">
            <span>Interest Expense</span>
          </div>
          <div class="zc-content-row">
            <span>Income Tax Expense</span>
          </div>
          <div class="zc-content-row">
            <span>Earnings Before Tax</span>
          </div>
          <div class="zc-content-row">
            <span>Net Income</span>
          </div>
          <div class="zc-content-row">
            <span>Earnings Per Share Basic</span>
          </div>
          <div class="zc-content-row">
            <span>Earnings Per Share Diluted</span>
          </div>
          <div class="zc-content-row">
            <span>Weighted Average Shares Outstanding</span>
          </div>
          <div class="zc-content-row">
            <span>Weighted Average Shares Outstanding (Diluted)</span>
          </div>
          <div class="zc-content-row">
            <span>Gross Margin</span>
          </div>
          <div class="zc-content-row">
            <span>EBIT Margin</span>
          </div>
          <div class="zc-content-row">
            <span>Profit Margin</span>
          </div>
          <div class="zc-content-row">
            <span>EBITDA</span>
          </div>
          <div class="zc-content-row">
            <span>Earnings Before Tax Margin</span>
          </div>
        </div>
        <div *ngFor="let statement of incomeStatements" class="zc-content-column">
          <div class="zc-content-row">
            <span>{{ statement.revenue | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.costOfRevenue | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.grossProfit | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.operatingExpenses | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.sellingGeneralAndAdministrativeExpenses | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.researchAndDevelopmentExpenses | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.costAndExpenses | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.operatingIncome | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.interestExpense | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.incomeTaxExpense | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.incomeBeforeTax | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.netIncome | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.eps | toFixed }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.epsdiluted | toFixed }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.weightedAverageShsOut | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.weightedAverageShsOutDil | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.grossProfitRatio | toFixed }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.operatingIncomeRatio | toFixed }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.netIncomeRatio | toFixed }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.ebitda | number:'1.0':'en-US' }}</span>
          </div>
          <div class="zc-content-row">
            <span>{{ statement.incomeBeforeTaxRatio | toFixed }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
