<div class="wrapper-upload-statements">
  <div class="wrapper-content-upload-statements">
    <div class="header-upload-statements">
      <span class="title-modal-upload">{{uploadFileStatus}}</span>
      <img (click)="uploadFileStatus === UploadFileStatus.uploadStatements ? closeModal.emit() : closeModalAndRedirect.emit()" src="../../../assets/icons/icon-close-modal.svg" alt="">
    </div>
    <div class="content-upload-statements">
      <label
        *ngIf="uploadFileStatus === UploadFileStatus.uploadStatements"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        class="wrapper-upload-pdf-file"
        [ngClass]="{'wrapper-upload-pdf-file--drag-over': isDragOver}"
        for="upload-pdf">
        <img src="../../../assets/icons/icon-upload-pdf.svg" alt="">
        <input type="file" accept=".pdf" id="upload-pdf" (change)="onFileChange($event)">
        <h6><span>Click to upload</span> or drag and drop</h6>
        <h6>Allowed file extensions: PDF</h6>
      </label>
      <div @fadeInAnimation *ngIf="uploadFileStatus === UploadFileStatus.uploading" class="uploading-pdf-file">
        <div class="progressbar-wrapper">
          <div class="progressbar-content">
            <div class="progressbar-overlay" [ngStyle]="{'width': uploadProgress + '%'}"></div>
          </div>
          <div class="percentage">{{uploadProgress}}%</div>
        </div>
      </div>
      <div @fadeInAnimation *ngIf="uploadFileStatus === UploadFileStatus.processing" class="processing-pdf-file">
        <div class="wrapper-pdf-preview">
          <div class="zc-d-flex zc-align-items">
            <img src="../../../assets/icons/icon-pdf.svg" alt="">
            <div class="info-pdf-preview">
              <span class="pdf-name">{{file?.name}}</span>
              <span class="pdf-size">{{file && (file.size | formatFileSize: false)}}</span>
            </div>
          </div>
        </div>
      </div>
      <div @fadeInAnimation *ngIf="uploadFileStatus === UploadFileStatus.uploadFailed" class="error-pdf-file">
        <div class="wrapper-pdf-preview">
          <div class="zc-d-flex zc-align-items">
            <img src="../../../assets/icons/icon-pdf.svg" alt="">
            <div class="info-pdf-preview">
              <span class="pdf-name">{{file?.name}}</span>
              <span class="error-message-pdf">Upload failed</span>
            </div>
          </div>
          <div class="zc-d-flex zc-align-items">
            <img (click)="removeFile()" class="zc-cursor-pointer" src="../../../assets/icons/icon-delete.svg" alt="">
            <img (click)="uploadFile()" class="zc-cursor-pointer" src="../../../assets/icons/icon-refresh.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

