import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PortfolioHttpService} from '../../services/http-services/portfolio/portfolio-http.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {UploadFileStatus} from '../../enums/portfolio-enums';
import {ToastrService} from 'ngx-toastr';
import {fadeInAnimation} from '../../animations/FadeInAnimation';
import {Router} from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';

@Component({
  selector: 'wx-upload-statements',
  templateUrl: './upload-statements.component.html',
  animations: [fadeInAnimation]
})
export class UploadStatementsComponent implements OnInit {
  @Input() portfolioId!: number;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModalAndRedirect: EventEmitter<void> = new EventEmitter<void>();
  isDragOver = false;
  uploadFileStatus = UploadFileStatus.uploadStatements;
  uploadProgress = 0;
  uploadForm!: FormGroup;
  file: File | null = null;
  protected readonly UploadFileStatus = UploadFileStatus;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private portfolioHttpService: PortfolioHttpService,
              private toasterHelperService: ToastrService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.uploadForm = this.formBuilder.group({
      file: [null, [Validators.required]],
    });
  }

  onFileChange(event: any): void {
    this.uploadProgress = 0;
    this.file = event.target.files[0];
    this.uploadFile();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.file = files[0];
      this.uploadFile();
    }
  }

  uploadFile(): void {
    if (this.file?.type !== 'application/pdf') {
      this.toasterHelperService.warning('Only .pdf format is supported');
      return;
    } else if (this.file?.size > 10485760) {
      this.toasterHelperService.warning('Document larger than 10MB');
      return;
    } else {
      this.uploadFileStatus = UploadFileStatus.uploading;
      const formData = new FormData();
      this.uploadForm.patchValue({file: this.file});
      formData.append('wxpdf', this.uploadForm.controls.file.value);
      this.portfolioHttpService.postUploadStatementReq({id: this.portfolioId}, formData).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.ResponseHeader:
              if (event.ok) {
                this.uploadFileStatus = UploadFileStatus.processing;
                setTimeout(() => {
                  this.router.navigate([PAGES_AND_PATHS.portfolio.pagePath]);
                }, 1500);
              } else {
                this.uploadFileStatus = UploadFileStatus.uploadFailed;
              }
              break;
            case HttpEventType.UploadProgress:
              // @ts-ignore
              this.uploadProgress = Math.round(event.loaded / event.total * 100);
              break;
          }
        }
      )
      ;
    }
  }

  removeFile(): void {
    this.uploadFileStatus = UploadFileStatus.uploadStatements;
    this.file = null;
    this.uploadForm.reset();
  }
}
