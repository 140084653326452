<div class="wrapper-content-page">
  <div class="header-wrapper-content-page">
    <div class="header-content-page-left">
      <img (click)="clickOnBackArrow.emit()" src="../../../../assets/icons/icon-back-arrow.svg" alt="">
      <span class="title-page">{{assetsData?.companyProfile[0]?.companyName}} {{assetsData?.companyProfile[0]?.symbol ? '[' + assetsData.companyProfile[0].symbol + ']' : ''}}</span>
    </div>
    <div class="header-content-page-right">

    </div>
  </div>
  <div class="wrapper-header-company-info xs-pb-0">
    <div class="zc-tabs-header">
      <div class="zc-tab" *ngFor="let tab of tabs" (click)="activeTab = tab" [ngClass]="{'active': activeTab === tab}">
        <span>{{tab}}</span>
      </div>
    </div>
  </div>
  <wx-overview-tab
    *ngIf="activeTab === InvestmentAssetsTabs.overview"
    [portfolioData]="activeAssetPortfolioData"
    [companyProfile]="assetsData?.companyProfile && assetsData.companyProfile[0]"
    [ratios]="assetsData?.keyInformation && assetsData.keyInformation[0]"
    [financialStatement]="assetsData?.incomeStatement && assetsData.incomeStatement"
    [keyMetrics]="assetsData?.keyMetrics && assetsData?.keyMetrics[0]"
    [peopleAlsoWatch]="assetsData?.peopleAlsoWach && assetsData.peopleAlsoWach"
    (clickOnPeopleAlsoWatchCompany)="onClickPeopleAlsoWatchCompany($event)">
  </wx-overview-tab>
  <wx-balance-sheet-tab
    *ngIf="activeTab === InvestmentAssetsTabs.balanceSheet"
    [balanceSheet]="assetsData?.balanceSheet && assetsData.balanceSheet">
  </wx-balance-sheet-tab>
  <wx-income-statement-tab
    *ngIf="activeTab === InvestmentAssetsTabs.incomeStatement"
    [incomeStatements]="assetsData?.incomeStatement && assetsData.incomeStatement">
  </wx-income-statement-tab>
  <wx-cash-flow-tab
    *ngIf="activeTab === InvestmentAssetsTabs.cashFlow"
    [cashFlow]="assetsData?.cashFlow && assetsData.cashFlow">
  </wx-cash-flow-tab>
</div>
