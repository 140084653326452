import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {WxPagination} from '../../../interfaces/general-interfaces';
import {PortfolioHttpService} from '../../../services/http-services/portfolio/portfolio-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WxClientPortfolio} from '../../../interfaces/portfolio-interfaces';
import {ToastrService} from 'ngx-toastr';
import {fadeInOutAnimation} from '../../../animations/FadeInOutAnimation';

@Component({
  selector: 'app-portfolio-statements-history',
  templateUrl: './portfolio-statements-history.component.html',
  animations: [fadeInOutAnimation]
})
export class PortfolioStatementsHistoryComponent implements OnInit {
  isUploadModalShow = false;
  historyStatements: any[] = [];
  historyStatementsPagination: WxPagination | undefined;
  singlePortfolioData!: WxClientPortfolio;
  statementForDelete: any = null;
  bodyParams = {
    portfolio_id: undefined,
    date_from: undefined,
    date_to: undefined,
    search_query: '',
  };
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private portfolioHttpService: PortfolioHttpService,
              private toastrService: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.singlePortfolioData = {...history.state.data};
      this.bodyParams.portfolio_id = res.id;
      this.getHistoryStatements();
    });
  }

  getHistoryStatements(): void {
    const page = (this.historyStatementsPagination?.currentPage) ? this.historyStatementsPagination.currentPage : 1;
    this.portfolioHttpService.postStatementsHistory({page}, this.bodyParams).then(res => {
      this.historyStatements = res.data.map((historyStatement: any) => {
        return {...historyStatement, total_portfolio: JSON.parse(historyStatement.total_portfolio), statement_date: JSON.parse(historyStatement.statement_date)};
      });
    });
  }

  onSelectStartDate(selectedDate: any): void {
    this.bodyParams.date_from = selectedDate;
    if (this.historyStatementsPagination?.currentPage) {
      this.historyStatementsPagination.currentPage = 1;
    }
    this.getHistoryStatements();
  }

  onSelectEndDate(selectedDate: any): void {
    this.bodyParams.date_to = selectedDate;
    if (this.historyStatementsPagination?.currentPage) {
      this.historyStatementsPagination.currentPage = 1;
    }
    this.getHistoryStatements();
  }

  onSearchChange(searchQuery: string): void {
    this.bodyParams.search_query = searchQuery;
    if (this.historyStatementsPagination?.currentPage) {
      this.historyStatementsPagination.currentPage = 1;
    }
    this.getHistoryStatements();
  }

  onPageChanged(page: any): void {
    if (this.historyStatementsPagination) {
      this.historyStatementsPagination.currentPage = page;
      this.getHistoryStatements();
    }
  }

  closeUploadStatementModalAndRedirect(): void {
    this.isUploadModalShow = false;
    this.router.navigate([PAGES_AND_PATHS.portfolio.pagePath]);
  }

  onConfirmDeleteStatement(): void {
    this.portfolioHttpService.deletePortfolioStatement({id: this.statementForDelete.id}).then(res => {
      this.getHistoryStatements();
      this.toastrService.success('Successfully deleted statement"');
      this.statementForDelete = null;
    });
  }
}
