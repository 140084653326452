import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'returnFormattedDatePickerPipe',
})
export class ReturnFormattedDatePickerPipe implements PipeTransform {
  transform(value: string): string {
    const [day, month, year] = value.split('/');
    return `${month}/${day}/${year}`;
  }
}
