import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatterNumberValuePipe',
})
export class FormatterNumberValuePipe implements PipeTransform {
  transform(value: string | number, typeForReturn: string = 'number'): any {
    return (value)
      ? Number(value.toString().replace(/[\s-$%,'A-Z,a-z]+/g, '').replace(/\.(\d{2})\d*$/, '.$1'))
      : typeForReturn === 'string'
        ? 'N/A'
        : 0;
  }
}
